<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  @import "../assets/styles/global.less";
  .element-box{
    position: absolute;
    right: 0;
    top: 0;
    text-align: left;
    transform: translate(0, -50%);

    .name{
      .bold();
      padding-bottom: 5px;
    }
  }

  .element-data{
    margin-bottom: 10px;
    th{
      padding-right: 10px;
    }
    th, td{
      padding-bottom: 5px;
    }
  }
  .tools-container{
    border: 1px solid @black;
    padding: 4px 0;
    font-size: 14px;
    > button{
      background: transparent;
      border: 0 none;
      padding: 10px 16px;
      text-transform: uppercase;
      border-left: 1px solid @black;
      &:first-child{
        border-left: none;
      }
      &.disabled{
        color: fade(@black, 40%);
      }
    }
    @media(max-width: @screen-md-max){
      padding: 0 4px;
      > button{
        font-size: 12px;
        padding: 6px 12px;
        display: block;
        width: 100%;
        padding: 10px 20px;
        border-left: none;
        border-top: 1px solid @black;
        &:first-child{
          border-top: none;
        }
      }
    }
  }


  //@media(orientation:portrait){
  .portrait &{
    .element-data, .name{
      display: none;
      @media(min-width: @screen-sm-min ){
        display: block;
      }
    }
    .element-box{
      right: 14px;
      @media(min-width: @screen-md-min ){
        right: 30px;
      }
    }
  }
  //@media(orientation:landscape){
  .landscape &{
    .element-data, .name{
      display: none;
      @media(min-width: @screen-lg-min ){
        display: block;
      }
    }
  }
</style>

<template>
  <div v-show="editingId" class="element-box" :style="{top: position.y + 'px'}">
    <p class="name">{{ instance.element.name }}</p>

    <table class="element-data">
      <tr>
        <th><span v-t="'label.height'"></span>:</th>
        <td>{{ instance.element.hg | convertToUnit(dimensionUnit) }} {{ dimensionUnit }}</td>
      </tr>
      <tr>
        <th><span v-t="'label.width'"></span>:</th>
        <td>{{ instance.element.wd | convertToUnit(dimensionUnit) }} {{ dimensionUnit }}</td>
      </tr>
      <tr>
        <th><span v-t="'label.weight'"></span>:</th>
        <td>{{ instance.element.weight | convertToUnit(weightUnit) }} {{ weightUnit }}</td>
      </tr>
      <tr>
        <th><span v-t="'label.power'"></span>:</th>
        <td>{{ instance.element.power }} W</td>
      </tr>
      <!--<tr>
        <th>Lumen Source:</th>
        <td>{{ instance.element.lm_s }} Lm</td>
      </tr>-->
      <!--<tr>
        <th><span v-t="'label.lumen'"></span>:</th>
        <td>{{ instance.element.lm_o }} Lm</td>
      </tr>-->
    </table>

    <div class="tools-container">
      <button
        :class="{'disabled': disableUp}"
        @click="debounceAction('moveUpInstance', { instanceId: instance.instanceId })"
      ><span class="visible-lg-inline" v-t="'label.up'"></span> <span class="icon-arrow-up"></span></button>
      <button
        :class="{'disabled': disableDown}"
        @click="debounceAction('moveDownInstance', { instanceId: instance.instanceId })"
      ><span class="visible-lg-inline" v-t="'label.down'"></span> <span class="icon-arrow-down"></span></button>
      <button
        @click="debounceAction('deleteInstance', { instanceId: instance.instanceId })"
      ><span class="visible-lg-inline" v-t="'label.remove'"></span> <span class="icon-close"></span></button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import TweenLite from 'gsap'
import Power1 from 'gsap/EasePack'
import debounce from 'lodash/debounce'
import { TWEEN_DURATION } from './ThreeComposition'

export default {
  props: {
    meshVector: {
      type: Object
    },
    instance: {
      type: Object,
      required: true
    },
    editingId: {
      required: true
    }
  },

  data () {
    return {
      position: {x: 0, y: 0},
      dimensionUnit: this.$env.dimensionUnit,
      weightUnit: this.$env.weightUnit
    }
  },

  watch: {
    meshVector: {
      immediate: true,
      handler (v) {
        TweenLite.to(this.position, TWEEN_DURATION, {
          x: 0,
          y: v.y,
          z: 0,
          ease: Power1.easeInOut
        })
      }
    }
  },

  methods: {
    ...mapMutations([
      'deleteInstance'
    ]),
    ...mapActions([
      'moveDownInstance',
      'moveUpInstance'
    ]),

    debounceAction: debounce(function (methodName, { instanceId }) {
      let fn = this[methodName]
      if (typeof fn === 'function') {
        fn({ instanceId })
      }
    }, 1000, { 'leading': true, 'trailing': false })
  },

  computed: {
    ...mapGetters([
      'instances'
    ]),

    /*
    * returns the number of instances with element.last not true (move down enabled)
    */
    moveDownInstancesLength () {
      return this.instances.filter(i => !i.element.last).length
    },

    editingIndex () {
      return this.$lodash.findIndex(this.instances, {instanceId: this.instance.instanceId})
    },

    disableUp () {
      return (this.editingIndex <= 0 || this.instance.element.last)
    },

    disableDown () {
      return (this.editingIndex >= (this.moveDownInstancesLength - 1) || this.instance.element.last)
    }
  }

}
</script>
