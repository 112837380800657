import maxBy from 'lodash/maxBy'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import Models from '../models/'
import * as THREE from 'three'
import Lodash from 'lodash'
// import Vue from 'vue'

export const STORAGE_KEY = 'arrangements-data'

export const DIM_OPTIONS = [
  { text: 'eur', value: 'eur' },
  { text: 'world', value: 'ul' }
]
export const POWERSUPPLY_OPTIONS = [
  { text: 'surface_mounted', value: 'embedded' },
  { text: 'recessed', value: 'remote' }
]

const ROOM_HEIGHT_DEFAULT = 2.7
export const ROOM_HEIGHT_MIN = 2.2
const ROOM_HEIGHT_MAX = 7
export const COMPOSITION_HEIGHT_MIN = 1.2
export const SPACE_TOP_MIN = 0.2
export const SPACE_BOTTOM_MIN = 0.2
export const TABLE_HEIGHT = 0.73
export const TABLE_DISTANCE = 0.6
export const WIRE_LENGTH_MAX = 2.5
const ELEMENT_OVERLAP = 0.04

export const getElementOverlap = (index) => {
  return (index > 0) ? ELEMENT_OVERLAP : 0
}
export const getElementHeight = (element, index, useNominalHg) => {
  let elementHg = (useNominalHg === true) ? element.hg : element._hg // show hg form json or the one form 3d models?
  if (element.model === 'Line') {
    return (index === 0) ? elementHg : 0
  } else {
    return elementHg - getElementOverlap(index)
  }
}

// for testing
if (navigator.userAgent.indexOf('PhantomJS') > -1) {
  window.localStorage.clear()
}

export const utils = {
  array_move (arr, oldIndex, newIndex) {
    if (newIndex >= arr.length) {
      var k = newIndex - arr.length + 1
      while (k--) {
        arr.push(undefined)
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
    return arr
  },
  convertToUnit (value, unit) {
    if (unit === 'in' || unit === 'cm') {
      value = value * 100
      value = (unit === 'in') ? value / 2.54 : value
      return parseInt(value * 10) / 10
    } else if (unit === 'lbs' || unit === 'kg') {
      value = (unit === 'lbs') ? value * 2.2046 : value
      return parseInt(value * 100) / 100
    }
  },
  convertFromUnit (value, unit) {
    if (unit === 'in' || unit === 'cm') {
      value = (unit === 'in') ? value * 2.54 : value
      return value / 100
    }
    if (unit === 'lbs' || unit === 'kg') {
      value = (unit === 'lbs') ? value / 2.2046 : value
    }
  }
}

const settingsDefault = {
  roomHeight: ROOM_HEIGHT_DEFAULT,
  wireLength: 0,
  tableHeight: 0,
  elements: [],
  dim: DIM_OPTIONS[0].value,
  dimSelector: true,
  powerSupply: POWERSUPPLY_OPTIONS[0].value,
  powerSupplySelector: true,
  showHelpSteps: true
}

// forced settings from window.app_env
const settingsOverrides = {}

if (Lodash.isString(window.app_env.dim)) {
  let v = window.app_env.dim
  let option = Lodash.find(DIM_OPTIONS, {value: v})
  if (option) {
    settingsOverrides.dim = v
    settingsOverrides.dimSelector = false
  }
}
if (Lodash.isString(window.app_env.powerSupply)) {
  let v = window.app_env.powerSupply
  let option = Lodash.find(POWERSUPPLY_OPTIONS, {value: v})
  if (option) {
    settingsOverrides.powerSupply = window.app_env.powerSupply
    settingsOverrides.powerSupplySelector = false
  }
}
if (Lodash.isNumber(window.app_env.ROOM_HEIGHT_DEFAULT)) {
  settingsOverrides.roomHeight = window.app_env.ROOM_HEIGHT_DEFAULT
}

const settingsStored = {}
// to start form latest combination, uncomment this
// const settingsStored = JSON.parse(window.localStorage.getItem(STORAGE_KEY) || '{}')

export const state = Object.assign({
  CompositionScreenshotData: null,
  elementsData: [],
  rosoniData: {},
  helpSteps: [],
  stateMessage: ''
}, settingsDefault, settingsStored, settingsOverrides) // merged form left to right (overrides on the right)

/* Initial instanceId */
let instanceId = 0
if (state.elements.length) {
  let maxInstanceId = maxBy(state.elements, function (o) { return o.instanceId })
  if (maxInstanceId) {
    instanceId = maxInstanceId.instanceId
  }
}

export const mutations = {
  setShowHelpSteps (state, value) {
    state.showHelpSteps = value
  },

  setCompositionScreenshotData (state, getDataFunction) {
    state.CompositionScreenshotData = getDataFunction
  },

  setMessage (state, messageKey) {
    state.stateMessage = messageKey
  },

  resetMessage (state) {
    state.stateMessage = ''
  },

  setWireLength (state, l) {
    if (l < SPACE_TOP_MIN) {
      l = SPACE_TOP_MIN
    }
    if (l > WIRE_LENGTH_MAX) {
      l = WIRE_LENGTH_MAX
    }
    l = parseInt(l * 100, 10) / 100
    state.wireLength = l
  },

  setRoomHeight (state, h) {
    if (h < ROOM_HEIGHT_MIN) {
      h = ROOM_HEIGHT_MIN
    }
    if (h > ROOM_HEIGHT_MAX) {
      h = ROOM_HEIGHT_MAX
    }
    h = parseInt(h * 100, 10) / 100
    state.roomHeight = h
  },

  setTableHeight (state, tableHeight) {
    state.tableHeight = tableHeight
  },

  setPowerSupply (state, powerSupply) {
    state.powerSupply = powerSupply
  },

  setDim (state, dim) {
    state.dim = dim
  },

  moveUpInstance (state, { instanceId }) {
    let index = findIndex(state.elements, ['instanceId', instanceId])
    if (index >= 1) {
      state.elements = utils.array_move(state.elements, index, index - 1)
    }
  },

  moveDownInstance (state, { instanceId }) {
    let index = findIndex(state.elements, ['instanceId', instanceId])
    if (index < (state.elements.length - 1)) {
      state.elements = utils.array_move(state.elements, index, index + 1)
    }
  },

  addInstance (state, { element, pushInstance }) {
    instanceId = instanceId + 1

    let newInstance = {instanceId: instanceId, elementId: element.id}
    if (pushInstance) {
      state.elements.push(newInstance)
    } else {
      state.elements.splice(state.elements.length - 1, 0, newInstance)
    }
  },

  deleteInstance (state, { instanceId }) {
    state.stateMessage = '' // todo: use an action and call resetMessage mutation
    state.elements = state.elements.filter(item => {
      return item.instanceId !== instanceId
    })
  },

  deleteAllInstances (state) {
    state.stateMessage = '' // todo: use an action and call resetMessage mutation
    state.elements = []
  }
}

export const actions = {
  setElementsData ({ state }, data) {
    let JSONLoader = new THREE.JSONLoader()
    let Loader = new THREE.Loader() // to parse and clone materials

    data.elements.forEach((item) => {
      let itemJson = Models.getModel(item.model)

      let itemJsonWithoutMaterial = {}
      Object.assign(itemJsonWithoutMaterial, itemJson, {materials: []})

      let jprs = JSONLoader.parse(itemJsonWithoutMaterial)
      if (!jprs.geometry.boundingBox) {
        jprs.geometry.computeBoundingBox()
      }
      item._hg = jprs.geometry.boundingBox.max.y - jprs.geometry.boundingBox.min.y
      item._wd = jprs.geometry.boundingBox.max.x - jprs.geometry.boundingBox.min.x

      /* origin correction */
      if (item.model === 'BrokenLine') {
        jprs.geometry.translate(0, item._hg, 0)
      }
      /* DropUp form DropDown model */
      if (item.upsidedown) {
        jprs.geometry.rotateX(Math.PI)
        jprs.geometry.translate(0, item._hg, 0)
      }

      /* a way to clone materials */
      item.createMesh = function () {
        /*
        * from JSONLoader.js source:
        * var materials = Loader.prototype.initMaterials( json.materials, texturePath, this.crossOrigin );
        */
        let itemMaterials = Loader.initMaterials(itemJson.materials)
        return new THREE.Mesh(jprs.geometry, itemMaterials)
      }
    })

    state.elementsData = data.elements

    state.rosoniData = data.rosoni

    state.helpSteps = data.helpSteps
  },

  setDataOverride ({ state }, {data, env}) {
    if (Lodash.isBoolean(data.priceEnable)) {
      env.priceEnable = data.priceEnable
    }
    if (Lodash.isString(data.priceCurrency)) {
      env.priceCurrency = data.priceCurrency
    }
    if (Lodash.isString(data.priceNote)) {
      env.priceNote = data.priceNote
    }

    // update state.rosoniData and state.elementsData
    data.products.forEach((product) => {
      let rose = Lodash.find(state.rosoniData, {floscode: product.floscode})
      if (rose) {
        rose = Object.assign(rose, product)
      }

      let element = Lodash.find(state.elementsData, {floscode: product.floscode})
      if (element) {
        element = Object.assign(element, product)
      }
    })
  },

  controlHeight ({ commit, getters }) {
    commit('resetMessage')
    while (getters.totalHeight > getters.totalHeightMax) {
      commit('deleteInstance', { instanceId: getters.instances[0].instanceId })
      commit('setMessage', 'message.elementsRemoved')
    }
  },

  setWireLength ({ commit, state, getters }, l) {
    let min = (state.roomHeight / ROOM_HEIGHT_MIN) * SPACE_TOP_MIN
    let max = state.roomHeight - getters.bottomSpace - getters.totalHeight

    if (l < min) {
      l = min
    }
    if (l > max) {
      l = max
    }
    commit('setWireLength', l)
  },

  setRoomHeight ({ commit, dispatch }, height) {
    commit('setRoomHeight', height)
    return dispatch('controlHeight')
  },

  setTableHeight ({ commit, dispatch }, {space, force = false}) {
    if (force === false) {
      space = Math.max(SPACE_BOTTOM_MIN, space)
    }
    commit('setTableHeight', space)
    return dispatch('controlHeight')
  },

  preserveBottomSpace ({ dispatch }) {
    return dispatch('setTableHeight', {space: TABLE_HEIGHT})
  },

  unpreserveBottomSpace ({ dispatch }) {
    return dispatch('setTableHeight', {space: 0, force: true})
  },

  moveUpInstance ({ commit, state, getters }, { instanceId }) {
    let index = findIndex(state.elements, ['instanceId', instanceId])
    if (index >= 1) {
      if (getters.instances[index].element.last !== true) {
        commit('moveUpInstance', { instanceId })
      }
    }
  },

  moveDownInstance ({ commit, state, getters }, { instanceId }) {
    let index = findIndex(state.elements, ['instanceId', instanceId])
    if (index < (state.elements.length - 1)) {
      if (getters.instances[index + 1].element.last !== true) {
        commit('moveDownInstance', { instanceId })
      }
    }
  },

  addInstance ({ commit, state, getters }, { element }) {
    commit('setMessage', '')
    let newItemHeight = getElementHeight(element, state.elements.length)

    // find last forced item (Line or BrokenLine)
    let instanceLast = find(getters.instances, function (o) { return o.element.last === true })
    let pushInstance = true
    let totalPower = getters.totalPower
    let totalHeight = getters.totalHeight
    if (element.last) {
      if (instanceLast) {
        // instanceLast will be removed, so its power and height could be subtsraterd from totals
        totalHeight = totalHeight - getElementHeight(instanceLast.element, state.elements.length)
        totalPower = totalPower - instanceLast.element.power
      }
    } else {
      if (instanceLast) {
        pushInstance = false
      }
    }

    if (totalHeight + newItemHeight > getters.totalHeightMax) {
      commit('setMessage', 'message.heightLimitExceeded')
      return false
    }

    if (totalPower + element.power > 190) {
      commit('setMessage', 'message.powerLimitExceeded')
      return false
    }

    if (element.last) {
      if (instanceLast) {
        commit('deleteInstance', { instanceId: instanceLast.instanceId })
        commit('setMessage', 'message.lastElementReplaced')
      }
    }

    commit('addInstance', { element, pushInstance })
  }
}
