import Vue from 'vue'
import VueRouter from 'vue-router'
import Intro from '@/components/Intro'
import Configurator from '@/components/Configurator'
import Welcome from '@/components/Welcome'
import Summary from '@/components/Summary'

Vue.use(VueRouter)

export default new VueRouter({
  routes: [
    {
      path: '/',
      name: 'intro',
      component: Intro
    },
    {
      path: '/welcome',
      name: 'welcome',
      components: {
        default: Configurator,
        panel: Welcome
      }
    },
    {
      path: '/configurator',
      name: 'configurator',
      component: Configurator
    },
    {
      path: '/summary',
      name: 'summary',
      components: {
        default: Configurator,
        panel: Summary
      }
    }
  ]
})
