<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
  @import "../assets/styles/global.less";

  .row-summary{
    margin-left: 0;
    margin-right: 0;
    > *{
      padding-left: 0;
      padding-right: 0;
    }

    .header{
      margin-bottom: 40px;
      .flos-logo{
        margin-bottom: 30px;
        float: none;
      }
      .lead{
        max-width: 640px;
      }
      @media(orientation:portrait){
        margin-bottom: 80px;
      }
    }

    .price-note {
      margin-bottom: 40px;
    }

    .th{
      text-transform: uppercase;
      .bold();
      padding: 10px 0;
      margin: 0;
    }

    @sidebarWidth: 190px;
    .col-image{
      text-align: center;
      margin-bottom: 60px;

      .composition-wrapper{
        position: relative;
      }

      .composition-canvas{
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
      }

      .composition-canvas{
        max-width: 180px;
        max-height: 600px;
      }
    }
    .col-data{
      // width: calc(~"100% - @{sidebarWidth}");
      .row-buttons > *{
        margin-bottom: 15px;
        @media(min-width: @screen-md-min){
          margin-bottom: 30px;
        }
      }
    }

    .btn-close{
      @size: 60px;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 30px;
      color: @black;
      width: @size;
      text-align: center;
      text-align: right;
      padding: 0;
    }

    .mrg-bottom{
      margin-bottom: 20px;
      @media(min-width: @screen-md-min){
        margin-bottom: 40px;
      }
    }
  }


  .table-data-elements{
    width: 100%;
    padding: 0;
    text-align: left;
    list-style: none;
    .light();
    th, td{
      padding: 10px 0;
      text-align: right;
      padding-left: 14px;
      &:first-child{
        text-align: left;
        padding-left: 0;
      }
    }
    th{
      text-transform: uppercase;
      .bold();
      padding-bottom: 16px;

      &.wide{
        min-width: 100px;
        @media(min-width: @screen-md-min){
          min-width: 180px;
        }
      }
    }

    .code{
      color: @grayLight;
    }
    .notes{
    }

    &.table-data-border{
      li, th, td{
        border-bottom: 1px solid #cccccc;
      }
    }

    .bold{
      .bold();
    }
    .big{
      .bold();
      /*
      @media(min-width: @screen-md-min){
        font-size: 24px;
      }
      */
    }
    .value{
      white-space: nowrap;
    }
  }
</style>

<template>
  <div class="summary">
    <div class="row row-summary panel-view-content">
      <div class="col-sm-4 col-lg-3 col-image hidden-xs">

      </div>
      <div class="col-sm-8 col-lg-9 col-data">
        <div class="header">
          <p class="icon-flos flos-logo"></p>
          <router-link class="btn-close" :to="{name: 'configurator'}"><span class="icon-close"></span></router-link>

          <h2 class="title" v-t="'message.summaryTitle'"></h2>
          <p class="lead" v-t="'message.summaryDescription'" v-if="downloadUrl || (quotationRequestUrl || storefinderUrl)"></p>
        </div>
        <div class="row">
          <div class="col-xs-6 col-sm-4 col-lg-4">
            <div class="table-data">
              <span class="name" v-t="'label.ceiling_height'"></span>
              <span class="value">{{ roomHeight | convertToUnit(dimensionUnit) }} {{ dimensionUnit }}</span>
            </div>
          </div>
          <div class="col-xs-6 col-sm-4 col-lg-4" v-if="tableHeight">
            <div class="table-data">
              <span class="name" v-t="'label.table_height'"></span>
              <span class="value">{{ tableHeight | convertToUnit(dimensionUnit) }} {{ dimensionUnit }}</span>
            </div>
          </div>
          <div class="col-xs-6 col-sm-4 col-lg-4">
            <div class="table-data">
              <span class="name" v-t="'label.boxes'"></span>
              <span class="value">{{ instances.length + 1 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row row-summary panel-view-content">
      <div class="col-sm-4 col-lg-3 col-image hidden-xs">
        <p class="th" v-t="'label.composition'"></p>

        <div class="composition-wrapper">
          <canvas class="composition-canvas" ref="compositionCanvas" />

          <div class="quote">
            <span class="start"></span>
            <span class="end"></span>

            <svg class="line" width="10" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
               <line stroke-dasharray="5, 5" x1="5" y1="0" x2="5" y2="100%" style="stroke-width: 1px;"></line>
            </svg>

            <span class="quote-value">
              {{ globalHeight | convertToUnit(dimensionUnit) }}<span class="unit">{{ dimensionUnit }}</span>
            </span>

            <div class="quote quote--wire-length" :style="wireLengthQuoteStyle">
              <span class="start"></span>
              <span class="end"></span>

              <svg class="line" width="10" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
                 <line stroke-dasharray="5, 5" x1="5" y1="0" x2="5" y2="100%" style="stroke-width: 1px;"></line>
              </svg>

              <span class="quote-value">
                {{ wireLength | convertToUnit(dimensionUnit) }}<span class="unit">{{ dimensionUnit }}</span>
              </span>
            </div>
            <div class="quote quote--total" :style="totalHeightQuoteStyle">
              <span class="start"></span>
              <span class="end"></span>

              <svg class="line" width="10" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
                 <line stroke-dasharray="5, 5" x1="5" y1="0" x2="5" y2="100%" style="stroke-width: 1px;"></line>
              </svg>

              <span class="quote-value">
                {{ totalHeight | convertToUnit(dimensionUnit) }}<span class="unit">{{ dimensionUnit }}</span>
              </span>
            </div>
          </div>
        </div>

      </div>
      <div class="col-sm-8 col-lg-9 col-data">
        <div class="scroll-horizontal-xs">
          <table class="table-data-elements table-data-border list-instances mrg-bottom" v-if="instances.length">
            <tr>
              <th class="wide" v-t="'label.elements'"></th>
              <th v-t="'label.height'"></th>
              <th v-t="'label.weight'"></th>
              <th v-t="'label.lumen'"></th>
              <th v-t="'label.power'"></th>
              <th v-if="priceEnable"><span v-t="'label.price'"></span><span v-if="priceNote">*</span></th>
            </tr>
            <tr v-if="rosone">
              <td>
                {{ rosone.name }}<br/>
                <span class="notes" v-if="rosone.notes">({{ rosone.notes }})<br/></span>
                <span class="code">{{ rosone.floscode }}</span>
              </td>
              <td class="value">{{ rosone.hg | convertToUnit(dimensionUnit) }} {{ dimensionUnit }}</td>
              <td class="value">{{ rosone.weight | convertToUnit(weightUnit) }} {{ weightUnit }}</td>
              <td></td>
              <td></td>
              <td v-if="priceEnable" class="value"><span v-if="rosone.price">{{ rosone.price | currency(priceCurrency, 2, { spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.', decimalSeparator: ',' }) }}</span></td>
            </tr>

            <tr
              v-for="(i) in instances"
              :key="i.instanceId"
            >
              <td>{{ i.element.name }}<br/><span class="code">{{ i.element.floscode }}</span></td>
              <td class="value">{{ i.element.hg | convertToUnit(dimensionUnit) }} {{ dimensionUnit }}</td>
              <td class="value">{{ i.element.weight | convertToUnit(weightUnit) }} {{ weightUnit }}</td>
              <td class="value">{{ i.element.lm_o }} Lm</td>
              <td class="value">{{ i.element.power }} W</td>
              <td v-if="priceEnable" class="value"><span v-if="i.element.price!=='null'">{{ i.element.price | currency(priceCurrency, 2, { spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.', decimalSeparator: ',' }) }}</span></td>
            </tr>
            <tr>
              <td class="bold">Total composition</td>
              <td class="big value">{{ totalHeight | convertToUnit(dimensionUnit) }} {{ dimensionUnit }}</td>
              <td class="big value">{{ totalWeight | convertToUnit(weightUnit) }} {{ weightUnit }}</td>
              <td class="big value">{{ totalLumenOutput }} Lm</td>
              <td class="big value">{{ totalPower }} W</td>
              <td v-if="priceEnable" class="big bold value">{{ totalPrice | currency(priceCurrency, 2, { spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.', decimalSeparator: ',' }) }}</td>
            </tr>
          </table>
        </div>

        <p v-if="priceEnable && priceNote" class="lead price-note" v-text="priceNote"></p>

        <div>
          <div class="row row-buttons">
            <div class="col-md-4" v-if="showDimSelector">
              <SettingItem setting-label="label.region" setting-name="dim" />
            </div>

            <div class="col-sm-6 col-md-4 pull-right-md" v-if="addToBasketUrl">
              <ButtonForm
                :actionUrl="addToBasketUrl"
                :buttonLabelKey="'label.add_to_basket'"
              ></ButtonForm>
            </div>

            <div class="col-sm-6 col-md-4 pull-right-md" v-if="downloadUrl">
              <ButtonForm
                :actionUrl="downloadUrl"
                :imageBase64="imageBase64"
                :buttonLabelKey="'label.download_pdf'"
                :buttonClass="(addToBasketUrl)?'border':''"
              ></ButtonForm>
            </div>

            <div class="col-sm-6 col-md-4 pull-right-md" v-if="quotationRequestUrl">
              <ButtonForm
                :actionUrl="quotationRequestUrl"
                :imageBase64="imageBase64"
                :buttonLabelKey="'label.request_a_quotation'"
                :buttonClass="''"
              ></ButtonForm>
            </div>

            <div class="col-sm-6 col-md-4 pull-right-md" v-if="storefinderUrl">
              <a class="btn-black full border" :href="storefinderUrl" target="_blank" v-t="'label.find_flos_dealer'"></a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ButtonForm from './ButtonForm.vue'

// https://gist.github.com/timdown/021d9c8f2aabc7092df564996f5afbbf
var trimCanvas = (function () {
  function rowBlank (imageData, width, y) {
    for (let x = 0; x < width; ++x) {
      if (imageData.data[y * width * 4 + x * 4 + 3] !== 0) {
        return false
      }
    }
    return true
  }

  function columnBlank (imageData, width, x, top, bottom) {
    for (let y = top; y < bottom; ++y) {
      if (imageData.data[y * width * 4 + x * 4 + 3] !== 0) {
        return false
      }
    }
    return true
  }

  return function (canvas, verticalOnly, horizontalOnly) {
    let ctx = canvas.getContext('2d')
    let width = canvas.width
    let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    let top = 0
    let bottom = imageData.height
    let left = 0
    let right = imageData.width

    while (top < bottom && rowBlank(imageData, width, top)) {
      ++top
    }
    while (bottom - 1 > top && rowBlank(imageData, width, bottom - 1)) {
      --bottom
    }
    while (left < right && columnBlank(imageData, width, left, top, bottom)) {
      ++left
    }
    while (right - 1 > left && columnBlank(imageData, width, right - 1, top, bottom)) {
      --right
    }

    let trimHeight = bottom - top
    let trimWidth = right - left
    if (verticalOnly) {
      left = 0
      trimWidth = canvas.width
    } else if (horizontalOnly) {
      top = 0
      trimHeight = canvas.height
    }
    let trimmed = ctx.getImageData(left, top, trimWidth, trimHeight)
    let copy = canvas.ownerDocument.createElement('canvas')
    let copyCtx = copy.getContext('2d')
    copy.width = trimmed.width
    copy.height = trimmed.height
    copyCtx.putImageData(trimmed, 0, 0)

    return copy
  }
})()

var brightnessFilter = function (canvas, k) {
  // Reduce brightness
  var imageData = canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height)
  var pixels = imageData.data
  // modify each pixel
  for (let i = 0; i < pixels.length; i += 4) {
    // red is pixels[i]
    // green is pixels[i + 1]
    // blue is pixels[i + 2]
    // alpha is pixels[i + 3]
    // all values are integers between 0 and 255
    pixels[i] = pixels[i] * k
    pixels[i + 1] = pixels[i + 1] * k
    pixels[i + 2] = pixels[i + 2] * k
  }
  return imageData
}

export default {
  components: {
    ButtonForm
  },
  data () {
    return Object.assign({
      imageBase64: null
    }, this.$env)
  },

  provide () {
    return {
      defaultFormMethod: this.defaultFormMethod,
      locale: this.locale,
      dataOvrUrl: this.dataOvrUrl,
      totalHeight: this.totalHeight,
      totalPrice: this.totalPrice,
      wireLength: this.wireLength,
      powerSupply: this.powerSupply,
      rosone: this.rosone,
      instances: this.instances,
      dimensionUnit: this.dimensionUnit,
      priceCurrency: this.priceCurrency
    }
  },

  computed: {
    ...mapState([
      'wireLength',
      'roomHeight',
      'tableHeight',
      'powerSupply',
      'dim',
      'CompositionScreenshotData'
    ]),

    ...mapGetters([
      'instances',
      'totalHeight',
      'totalPrice',
      'totalWeight',
      'totalPower',
      'totalLumenSource',
      'totalLumenOutput',
      'rosone',
      'showDimSelector',
      'compostionString'
    ]),

    wireLengthQuoteStyle () {
      return {
        bottom: 'auto',
        height: (this.wireLength / (this.wireLength + this.totalHeight) * 100) + '%',
        left: '20px'
      }
    },

    totalHeightQuoteStyle () {
      return {
        top: 'auto',
        height: (this.totalHeight / (this.wireLength + this.totalHeight) * 100) + '%',
        left: '20px'
      }
    },

    globalHeight () {
      return this.wireLength + this.totalHeight
    }
  },

  mounted () {
    let _self = this
    this.$nextTick(function () {
      if (typeof _self.CompositionScreenshotData === 'function') {
        let {imgWidth, imgHeight, imgData} = _self.CompositionScreenshotData()
        let ctxCopy = document.createElement('canvas').getContext('2d')
        // ctxCopy.antialias = 'subpixel'
        // ctxCopy.imageSmoothingEnabled = true
        // ctxCopy.patternQuality = 'best'
        // ctxCopy.imageSmoothingQuality = 'high'
        ctxCopy.canvas.width = imgWidth  // cvWidth
        ctxCopy.canvas.height = imgHeight // cvHeight
        let img = new Image()
        img.onload = function () {
          ctxCopy.drawImage(img, 0, 0, imgWidth, imgHeight)
          let trimmedCanvas = trimCanvas(ctxCopy.canvas)
          let ctx = _self.$refs.compositionCanvas.getContext('2d')
          ctx.canvas.width = trimmedCanvas.width
          ctx.canvas.height = trimmedCanvas.height
          ctx.putImageData(brightnessFilter(trimmedCanvas, 0.7), 0, 0)
          _self.imageBase64 = _self.$refs.compositionCanvas.toDataURL('image/png')
        }
        img.src = imgData
      }
    })
  }
}
</script>
