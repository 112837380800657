<style lang="less">
  @import "../assets/styles/global.less";

  .welcome{
    @media(max-width: @screen-xs-max){
      padding-left: 30px;
      padding-right: 30px;
    }

    .panel-header{
      text-align: center;
      .flos-logo{
        float: none;
      }
    }
    .header{
      margin: 0 auto 40px;
      max-width: 700px;
      padding: 0 20px;
      text-align: center;
      @media(min-width: @screen-sm-min){
        margin-bottom: 80px;
      }
    }

    @buttonHeight: 70px;

    .btn-next{
      display: block;
      margin: 0 auto;
      max-width: 500px;
      line-height: 40px;
      @media(max-width: @screen-xs-max){
        max-width: 100%;
      }
    }


    //@media(orientation:portrait){
    .portrait &{
      @media(min-width: @screen-md-min){
        .panel-header{
          margin-bottom: 100px;
        }
        .header{
          margin-bottom: 120px;
        }
        .row-settings{
          margin-bottom: 80px;
          p{
            margin-bottom: 60px;
          }
          .setting-group, .input-edit{
            margin-bottom: 80px;
          }
        }
      }
    }
  }

  .row-settings{
    text-align: center;

    @media(max-width: @screen-xs-max){
      [class^="col-"], [class*=" col-"] {
        padding: 0 0 10px;
      }
    }

    @media(min-width: @screen-sm-min){
      margin-bottom: 60px;
      .rowEqualHeight(@verticalAlign: top);

      [class^="col-"], [class*=" col-"] {
        text-align: center;
        border-right: 1px solid @grayLight;
        &:last-child{
          border-right: none;
        }
      }
    }

    p{
      margin: 0 auto 10px;
      font-size: 16px;
      .bold();
      @media(min-width: @screen-sm-min){
        margin-bottom: 20px;
        max-width: 210px;
        height: 40px;
      }
    }

    .input-edit{
      margin: 0 auto;
    }

    .input-edit, .setting-group{
      margin-bottom: 30px;
    }

    .img-options{
      position: relative;
      padding-bottom: 240px;
    }
    .img-option{
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%;);
      max-height: 100%;
    }
    @media(max-width: @screen-xs-max){
      .img-options{
        padding-bottom: 70%;
      }
    }
  }

</style>

<template>
  <div class="welcome">
    <div class="panel-header">
      <span class="icon-flos flos-logo"></span>
    </div>

    <div class="header">
      <h2 class="app-logo"><img class="img-responsive" src="../assets/images/arrangements-logo.svg" /></h2>
      <p class="lead" v-t="'message.welcome'"></p>
    </div>

    <div class="panel-view-content">
      <div class="row-settings">
        <div class="col-sm-4">
          <p v-t="'message.welcomeRoomHeight'"></p>
          <InputNumber
            :unit="dimensionUnit"
            size="3"
            v-model="roomHeightValue"
          />
          <div class="hidden-xs img-options">
            <img class="img-option" src="../assets/images/welcome/room-height.svg" />
          </div>
        </div>
        <div class="col-sm-4">
          <p v-t="'message.welcomeTable'"></p>
          <SettingItem setting-name="tableToggle" />

          <div class="hidden-xs img-options">
            <transition name="fade">
              <img class="img-option" v-if="tableHeight > 0" src="../assets/images/welcome/table.svg" key="table" />
              <img class="img-option" v-else src="../assets/images/welcome/free-standing.svg" key="free-standing" />
            </transition>
          </div>

        </div>
        <div class="col-sm-4" v-if="powerSupplySelector">
          <p v-t="'message.welcomePowerSupply'"></p>
          <SettingItem setting-name="powerSupply" />

          <div class=" hidden-xs img-options">
            <transition name="fade">
              <img class="img-option" v-if="powerSupply === POWERSUPPLY_OPTIONS[0].value" src="../assets/images/welcome/powersupply-embedded.svg" key="embedded" />
              <img class="img-option" v-else src="../assets/images/welcome/powersupply-remote.svg" key="remote" />
            </transition>
          </div>
        </div>
      </div>
    </div>

    <router-link class="btn-black border btn-next" :to="{name: 'configurator'}" v-t="'label.next'"></router-link>
  </div>
</template>

<script>
import InputNumber from './InputNumber.vue'
import { mapState, mapActions } from 'vuex'
import { POWERSUPPLY_OPTIONS, utils } from '../store/mutations'

export default {
  components: {
    InputNumber
  },

  data () {
    return {
      POWERSUPPLY_OPTIONS,
      dimensionUnit: this.$env.dimensionUnit,
      weightUnit: this.$env.weightUnit
    }
  },

  computed: {
    ...mapState([
      'tableHeight',
      'roomHeight',
      'powerSupply',
      'powerSupplySelector'
    ]),
    roomHeightValue: {
      get () {
        return utils.convertToUnit(this.roomHeight, this.dimensionUnit)
      },
      set (value) {
        this.setRoomHeight(utils.convertFromUnit(value, this.dimensionUnit))
      }
    }
  },

  methods: {
    ...mapActions([
      'setRoomHeight'
    ])
  }
}
</script>
