
<template>
  <form :action="actionUrl" target="_blank" :method="formMethod">
    <input type="hidden" :value="locale" name="locale"/>
    <input type="hidden" :value="dataOvrUrl" name="dataOvrUrl"/>
    <input type="hidden" :value="totalHeight" name="totalHeight"/>
    <input type="hidden" :value="totalPrice" name="totalPrice"/>
    <input type="hidden" :value="priceCurrency" name="priceCurrency"/>
    <input type="hidden" :value="dimensionUnit" name="dimensionUnit"/>
    <input type="hidden" :value="powerSupply" name="powerSupply"/>
    <input v-if="imageBase64" type="hidden" :value="imageBase64" name="imageData"/>

    <input v-if="rosone" type="hidden" :value="rosone.prodcode" :name="'element[0]'"/>
    <input v-for="(i, index) in instances" type="hidden" :value="i.element.prodcode" :name="'element[' + (index + 1) + ']'" :key="index + '-' + i.element.prodcode" />

    <input v-if="rosone" type="hidden" :value="rosone.floscode" :name="'floscode[0]'"/>
    <input v-for="(i, index) in instances" type="hidden" :value="i.element.floscode" :name="'floscode[' + (index + 1) + ']'" :key="index + '-' + i.element.floscode" />

    <button class="btn-black full" :class="buttonClass" type="submit" v-t="buttonLabelKey"></button>
  </form>
</template>

<script>
export default {
  inject: ['defaultFormMethod', 'locale', 'dataOvrUrl', 'totalHeight', 'totalPrice', 'wireLength', 'powerSupply', 'rosone', 'instances', 'dimensionUnit', 'priceCurrency'],

  props: {
    buttonClass: {
      type: String
    },
    buttonLabelKey: {
      type: String
    },
    actionUrl: {
      type: String
    },
    imageBase64: {
      type: String
    }
  },

  computed: {
    formMethod () {
      if (this.imageBase64) {
        return 'post'
      } else {
        return this.defaultFormMethod // 'post' as default
      }
    }
  }
}
</script>
