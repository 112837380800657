<template>
  <div class="hidden">
    {{ instance.element.name }}
  </div>
</template>

<script>
import * as THREE from 'three'
import TweenLite from 'gsap'
import Power1 from 'gsap/EasePack'
import { TWEEN_DURATION } from './ThreeComposition'

const BoundMaterial = new THREE.MeshLambertMaterial({color: 0xffffff, transparent: true, opacity: 0})

const utils = {
  getCenterVector (obj) {
    return new THREE.Box3().setFromObject(obj).getCenter()
  },
  getVectorProjection (vector, renderer, camera) {
    let canvas = renderer.domElement // renderer.context.canvas
    let dpr = renderer.getPixelRatio()
    vector.project(camera)
    vector.x = Math.round((vector.x + 1) * canvas.width / 2) / dpr
    vector.y = Math.round((-vector.y + 1) * canvas.height / 2) / dpr
    vector.z = 0

    vector.y = Math.min(vector.y, canvas.height - 200) // limit in case of zoom
    return vector
  }
}

export default {
  inject: ['renderer', 'camera', 'group'],

  props: {
    instance: {
      type: Object,
      required: true
    },
    position: {
      type: Number,
      required: true
    },
    rotation: {
      type: Number,
      required: true
    },
    editingId: {
      required: true
    }
  },

  data () {
    let mesh = this.instance.element.createMesh()
    mesh.position.set(0, this.position, 0)

    /* clickable boundbox */
    let boundHeight = (this.instance.element._hg > 0.4) ? this.instance.element._hg : 0.4
    let boundGeometry = new THREE.BoxGeometry(this.instance.element.wd + 0.02, boundHeight, 0.2)
    boundGeometry.translate(0, this.instance.element._hg / 2, 0)
    let bound = new THREE.Mesh(boundGeometry, BoundMaterial)
    bound.position.set(0, mesh.position.y, 0)

    mesh.rotation.set(0, this.rotation, 0)
    bound.rotation.set(0, this.rotation, 0)

    mesh.userData.instanceId = this.instance.instanceId
    bound.userData.instanceId = this.instance.instanceId

    let materialWhite = this.$lodash.find(mesh.material, {name: '_0_bianco'})
    let materialBlack = this.$lodash.find(mesh.material, {name: '_0_nero'})
    materialBlack.specular = new THREE.Color(0x000000) // non reflective (default 0x111111 (very dark grey))

    return {
      bound: bound,
      mesh: mesh,
      materialWhite: materialWhite,
      materialBlack: materialBlack
    }
  },

  mounted () {
    this.$emit('editingReset')

    this.group.add(this.mesh)
    this.group.add(this.bound)

    let positionY = this.mesh.position.y
    TweenLite.fromTo(this.mesh.position, TWEEN_DURATION, {
      x: 0,
      y: positionY + 0.4,
      z: 0
    }, {
      x: 0,
      y: positionY,
      z: 0,
      ease: Power1.easeInOut
    })
  },

  methods: {
    activeMaterial (active) {
      let w = 0xdddddd
      let b = 0xbbbbbb
      if (active) {
        w = 0xffffff
        b = 0x000000
      }
      if (this.materialWhite) {
        this.materialWhite.color.set(w)
      }
      if (this.materialBlack) {
        this.materialBlack.color.set(b)
      }
    }
  },

  watch: {
    editingId: {
      immediate: true,
      handler (value) {
        let v = null
        if (value !== null) {
          if (this.instance.instanceId === value) {
            v = true
          } else {
            v = false
          }
        }
        if (v === true) {
          this.activeMaterial(true)
          this.$emit('element:setBoxPosition', utils.getVectorProjection(utils.getCenterVector(this.mesh), this.renderer, this.camera))
        } else if (v === false) {
          this.activeMaterial(false)
        } else if (v === null) {
          this.activeMaterial(true)
        }
      }
    },

    position: {
      immediate: true,
      handler (v) {
        if (this.instance.instanceId === this.editingId) {
          let deltaY = v - this.mesh.position.y
          let meshCenter = utils.getCenterVector(this.mesh)
          meshCenter.y = meshCenter.y + deltaY
          let meshVector = utils.getVectorProjection(meshCenter, this.renderer, this.camera)
          this.$emit('element:setBoxPosition', meshVector)
        }

        TweenLite.to(this.mesh.position, TWEEN_DURATION, {
          x: 0,
          y: v,
          z: 0,
          ease: Power1.easeInOut,
          onComplete: () => {
            this.bound.position.y = v
          }
        })
      }
    },

    rotation: {
      immediate: true,
      handler (v) {
        TweenLite.to(this.mesh.rotation, TWEEN_DURATION, {
          x: 0,
          y: v,
          z: 0,
          ease: Power1.easeInOut,
          onComplete: () => {
            this.bound.rotation.y = v
          }
        })
      }
    }
  },

  beforeDestroy () {
    this.$emit('editingReset')
    this.group.remove(this.mesh)
    this.group.remove(this.bound)
  }
}
</script>
