const messages = {
  label: {
    'start_now': 'start now',
    'no_table': 'free suspension',
    'over_table': 'over a table',
    'surface_mounted': 'surface mounted',
    'recessed': 'recessed',
    'proceed': 'Are you finished? Proceed!',
    'power': 'Power',
    'lumen': 'Lumen',
    'up': 'Up',
    'down': 'Down',
    'remove': 'Remove',
    'height': 'Height',
    'width': 'Width',
    'weight': 'Weight',
    'price': 'Price',
    'zoom_out': 'zoom out',
    'prev': 'Prev',
    'next': 'Next',
    'composition': 'composition',
    'elements': 'elements',
    'ceiling_height': 'Ceiling height',
    'table_height': 'Table height',
    'wire_length': 'Wire length',
    'boxes': 'Boxes',
    'request_a_quotation': 'Find the store',
    'find_flos_dealer': 'find flos dealer',
    'download_pdf': 'download pdf',
    'add_to_basket': 'Add to basket',
    'positioning': 'Positioning',
    'region': 'Region',
    'rose': 'Ceiling rose',
    'dim_select': 'Choose the region',
    'settings': 'Settings'
  },
  message: {
    'welcome': 'Make three simple choices before starting your configuration, remember that you can change them whenever you want.',
    'welcomeRoomHeight': 'How high is your ceiling?',
    'welcomeTable': 'Where do you want to install the light?',
    'welcomePowerSupply': 'What kind of ceiling rose do you need?',
    'configuratorClickCta': 'Click on the elements in the list to create your Arrangements.',
    'powerLimitExceeded': 'You cannot add this element, as the maximum power would be exceeded.',
    'heightLimitExceeded': 'You cannot add this element, as the maximum height would be exceeded.',
    'elementsRemoved': 'We have removed some elements to keep within the new height limit. ',
    'lastElementReplaced': 'Line and Broken Line are two alternative elements. Only one can be used in your Arrangements',
    'summaryTitle': 'Your Arrangements is ready',
    'summaryDescription': 'If you like your composition you can download this sheet to keep it with you or send it to our official dealer to get a quote',
    'notInstallableAlone': 'Line cannot be installed alone, it must be combined with other elements'
  },
  help: {
    'title': 'Welcome!',
    'roomHeight': 'Change the height of your ceiling by clicking on the height field.',
    'positioning': 'Select if your Arrangements is located above a table.',
    'powerSupply': 'Select if the power supply is integrated in the ceiling rose or outside it, in the event of a false ceiling.',
    'elementSelection': 'Add the elements to your position. You can move them up or down or remove them. ',
    'proceed': 'When you\'re happy with your Arrangements click Proceed to go to the summary sheet.'
  }
}

export default messages
