import { STORAGE_KEY } from './mutations'

const localStoragePlugin = store => {
  store.subscribe((mutation, { roomHeight, tableHeight, elements, dim, powerSupply, showHelpSteps }) => {
    let obj = {roomHeight, tableHeight, elements, dim, powerSupply, showHelpSteps}

    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(obj))
  })
}

export default process.env.NODE_ENV !== 'production'
  ? [localStoragePlugin]
  : [localStoragePlugin]
