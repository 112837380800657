<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
  .composition-not-empty{
    cursor: url('~@/assets/images/pointer-rotate.svg'), auto;
    &.element-mousover{
      cursor: url('~@/assets/images/pointer-edit.svg'), auto;
    }
  }
</style>

<template>
  <div ref="wrapper" class="composition-wrapper">
    <div ref="canvasContainer" class="canvas-container" :wirePosition="wirePosition" :class="{'element-mousover': elementMouseOver, 'composition-not-empty': instances.length}"></div>

    <div class="tools" v-if="instances.length && startDelay">
      <ThreeCompositionElement
        v-for="(i, index) in instances"
        :key="i.instanceId"
        :instance="i"
        :position="instancesPositions[index]"
        :rotation="instancesRotations[index]"
        :editingId="editingId"
        @editingReset="editingIdReset"
        @element:setBoxPosition="elementSetBoxPosition"
      />
    </div>

    <ThreeCompositionBox
      :meshVector="meshVector"
      :editingId="editingId"
      :instance="editingInstance"
      v-if="editingInstance"
    />
  </div>
</template>

<script>
import * as THREE from 'three'
import TweenLite from 'gsap'
import { Power1, Power2, Power3 } from 'gsap/EasePack'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { getElementOverlap } from '../store/mutations'
import Models from '../models/'
import Hammer from 'hammerjs' // http://hammerjs.github.io/api/
import throttle from 'lodash/throttle'
import ThreeCompositionElement from './ThreeCompositionElement.vue'
import ThreeCompositionBox from './ThreeCompositionBox.vue'
import { detector } from '../detector'

// const addWheelListener = require('wheel').addWheelListener
// const removeWheelListener = require('wheel').removeWheelListener

export const TWEEN_DURATION = 0.5
export const OBJECT_TWEEN_DURATION = 2

const utils = {
  getTime: () => { return new Date().getTime() },
  toRadians: (angle) => {
    return angle * (Math.PI / 180)
  },
  toDegrees: (angle) => {
    return angle * (180 / Math.PI)
  },
  objectDistanceFromHeight: (objectHeight, camera) => {
    return Math.abs((objectHeight) / (2 * Math.tan(utils.toRadians(camera.fov) / 2)))
  }
}

const dpr = (window.devicePixelRatio !== undefined) ? window.devicePixelRatio : 1
let $html = document.querySelector('html')
let wd = 800
let hg = 600

// https://threejs.org/docs/#api/core/Raycaster
const raycaster = new THREE.Raycaster()
const threeScene = new THREE.Scene()
const mouseVector = new THREE.Vector2()
const FOV_MAX = 10
// const FOV_MIN = 5
const ZOOM_MAX = 2.5
const ZOOM_MIN = 1
const threeCamera = new THREE.PerspectiveCamera(FOV_MAX, wd / hg, 1, 100)
// threeCamera.position.set(0,0,0)
// threeCamera.lookAt(scene.position)

// test fallback renders?  THREE.CanvasRenderer()
// https://stackoverflow.com/questions/15558418/how-do-you-save-an-image-from-a-three-js-canvas
const threeRenderer = (!detector.webgl) ? null : new THREE.WebGLRenderer({antialias: true, alpha: true}) // , preserveDrawingBuffer: true not set to true to preserve performance

// threeRenderer.setClearColor(0xeeeeee, 0)
if (threeRenderer) {
  threeRenderer.setPixelRatio(dpr)
  threeRenderer.setSize(wd, hg)
}

let threeAnimateFrame = null // id reference of requestAnimationFrame
let light = new THREE.AmbientLight(0xffffff, 1)
threeScene.add(light)
/*
let directionalLight = new THREE.DirectionalLight(0xffffff, 0.4)
directionalLight.position.set(0, 3, 3)
threeScene.add(directionalLight)
*/
const threeGroup = new THREE.Group()
threeGroup.position.set(0, 0, -20)
threeScene.add(threeGroup)
let threeGroupStartRotationY = Math.PI / 4

// const renderScene = new THREE.RenderPass(scene, camera);
// effectFXAA = new THREE.ShaderPass(THREE.FXAAShader);
// effectFXAA.uniforms['resolution'].value.set(1 / (wd * dpr), 1 / (hg * dpr));
// effectFXAA.renderToScreen = true;

/*
This composer will actually replace how we update the rendering of our application. Where we normally call renderer.render(scene, camera); to render our scene. We will instead call composer.render(); in our render loop to draw our scene.
*/
/*
const composer = new THREE.EffectComposer(renderer);
composer.setSize(wd * dpr, hg * dpr);
composer.addPass(renderScene);
composer.addPass(effectFXAA);
*/

// let _stats = new Stats()
const addRect = false
let rectMesh = null
let DrawRec = function () {
  let rectWidth = 1
  let rectLength = 1
  let rectShape = new THREE.Shape()
  rectShape.moveTo(0, 0)
  rectShape.lineTo(0, rectWidth)
  rectShape.lineTo(rectLength, rectWidth)
  rectShape.lineTo(rectLength, 0)
  rectShape.lineTo(0, 0)
  let geometry = new THREE.ShapeBufferGeometry(rectShape)
  let rectMesh = new THREE.Mesh(geometry, new THREE.MeshPhongMaterial({color: 0xf0f0f0, side: THREE.DoubleSide}))
  rectMesh.position.set(0, 0, 0)
  return rectMesh
}
if (addRect) {
  rectMesh = DrawRec()
  threeGroup.add(rectMesh)
}

let DrawWire = function () {
  let loader = new THREE.JSONLoader()

  var r = loader.parse(Models.getModel('Wire'))
  if (!r.geometry.boundingBox) {
    r.geometry.computeBoundingBox()
  }
  r.hg = r.geometry.boundingBox.max.y - r.geometry.boundingBox.min.y
  r.wd = r.geometry.boundingBox.max.x - r.geometry.boundingBox.min.x

  let mesh = new THREE.Mesh(r.geometry, r.materials)
  // mesh.scale.x = mesh.scale.y = mesh.scale.z = 0.02

  mesh.material[0].color.set(0x777777)
  return mesh
}
let wire = DrawWire()
threeGroup.add(wire)

let rosone = null
let rosoneMaterial = new THREE.MeshLambertMaterial({color: 0x000000})

let hammertime = null // hammer instance
let touchObj = {
  prevMove: {x: 0, y: 0},
  currMove: {x: 0, y: 0},
  deltaMove: {x: 0, y: 0},
  threeObjTween: null,
  zoom: ZOOM_MIN,
  offsetY: 0,
  pinchS: 0
}

export default {
  provide () {
    return {
      renderer: threeRenderer,
      camera: threeCamera,
      group: threeGroup
    }
  },

  components: {
    ThreeCompositionElement,
    ThreeCompositionBox
  },

  data () {
    return {
      startDelay: false,
      dragging: false,
      editingId: null,
      editingInstance: null,
      elementsHeight: 0,
      elementsOffset: 0,
      zoomActive: false,
      elementMouseOver: false,
      meshVector: new THREE.Vector3()
    }
  },

  computed: {
    ...mapState([
      'elements',
      'roomHeight',
      'tableHeight',
      'wireLength'
    ]),

    ...mapGetters([
      'instances',
      'rosone',
      'bottomSpace',
      'totalHeight'
    ]),

    instancesPositions () {
      let positions = []
      let p = 0

      this.instances.forEach((i, index) => {
        p = p - i.element._hg + getElementOverlap(index)
        positions.push(p)
      })

      let offset = 0
      if (positions.length) {
        if (!this.wireLength) {
          // set an initial medium wire length
          this.setWireLength((this.roomHeight - this.totalHeight - this.bottomSpace) / 2)
        } else {
          this.setWireLength(this.wireLength)
        }

        offset = -((-this.roomHeight / 2) + this.wireLength)

        positions = this.$lodash.map(positions, (n) => {
          return n + offset
        })
      }
      return positions
    },

    instancesRotations () {
      let rotations = []
      this.instances.forEach((i, index) => {
        let r = (index % 2 === 0) ? 0 : Math.PI / 2
        rotations.push(r)
      })
      return rotations
    },

    /*
    * attribute on ref="container" to force computation
    */
    wirePosition () {
      let p = this.roomHeight / 2 + 0.5 // out of scene
      let rosoneP = p
      /*
      * reference to this.elementsHeight
      * because instancesPositions is not updated when this.elements.length = 0
      */
      if (this.elements.length && this.elementsHeight) {
        p = this.elementsOffset - 0.02
        rosoneP = this.roomHeight / 2
      }

      if (rosone) {
        TweenLite.to(rosone.position, OBJECT_TWEEN_DURATION, {
          x: 0,
          y: rosoneP,
          z: 0,
          ease: Power3.easeOut
        })
      }

      if (wire) {
        TweenLite.to(wire.position, TWEEN_DURATION, {
          x: 0,
          y: p,
          z: 0,
          ease: Power1.easeOut
        })
      }

      return p
    }
  },

  watch: {
    instancesPositions: {
      deep: true,
      handler: function (positions) {
        let offset = 0
        let elementsHeight = 0

        if (positions.length) {
          elementsHeight = this.totalHeight
          offset = -((-this.roomHeight / 2) + this.wireLength)
        } else {
          elementsHeight = 0
          offset = 0
        }

        this.elementsOffset = offset

        this.elementsHeight = elementsHeight
      }
    },

    rosone: {
      immediate: true,
      handler (vNew) {
        if (vNew) {
          if (rosone) {
            threeGroup.remove(rosone)
          }
          // CylinderGeometry(radiusTop, radiusBottom, height, radiusSegments, heightSegments, openEnded, thetaStart, thetaLength)
          let rosoneGeometry = new THREE.CylinderGeometry(vNew.wd / 2, vNew.wd / 2, vNew.hg, 32, 1)
          rosoneGeometry.translate(0, -vNew.hg / 2, 0)
          rosone = new THREE.Mesh(rosoneGeometry, rosoneMaterial)
          rosone.position.y = this.elementsOffset

          if (this.roomHeight) {
            let rosoneP = this.roomHeight / 2
            if (!this.elements.length) {
              rosoneP = this.roomHeight / 2 + 0.5
            }
            rosone.position.y = rosoneP
          }

          threeGroup.add(rosone)
        }
      }
    },

    roomHeight: {
      immediate: true,
      handler (vNew) {
        if (rectMesh) {
          rectMesh.scale.set(1, vNew, 1)
          rectMesh.position.set(0, -vNew / 2, 0)
        }

        let threeGroupDistance = utils.objectDistanceFromHeight(vNew, threeCamera)
        TweenLite.to(threeGroup.position, OBJECT_TWEEN_DURATION, {
          x: 0,
          y: 0,
          z: -threeGroupDistance,
          ease: Power3.easeOut
        })
      }
    },

    zoomActive: {
      immediate: true,
      handler (vNew) {
        if (vNew === true) {
          // close editing box
          this.editingIdReset()
        }
        this.$emit('update:zoomActive', vNew)
      }
    }
  },

  mounted () {
    let _self = this
    this.$nextTick(function () {
      $html = document.querySelector('html')

      let threeGroupDistance = utils.objectDistanceFromHeight(_self.roomHeight, threeCamera)
      threeGroup.position.set(0, 0, -(threeGroupDistance)) // + 1

      let p = _self.roomHeight / 2 + 0.5
      if (_self.elements.length) {
        p = _self.elementsOffset - 0.02
      }
      wire.position.set(0, p, 0)

      _self.$refs.canvasContainer.appendChild(threeRenderer.domElement)

      if (detector.webgl) {
        _self.threeAnimate()
      }

      _self.setCompositionScreenshotData(_self.getCompositionDataURL)

      _self.startDelay = true

      _self.initDrag()
      _self.orientationClass()
      _self.updateLayout()

      _self.$refs.canvasContainer.addEventListener('mousemove', _self.onDocumentMouseMove, false)
      window.addEventListener('resize', _self.onWindowResize, false)
      window.addEventListener('orientationchange', _self.onWindowOrientationChanged, false)
      // addWheelListener(_self.$refs.canvasContainer, _self.onMouseWheel)

      document.querySelector('html').classList.add('composition-initialized')
      _self.$refs.canvasContainer.classList.add('composition-initialized')

      TweenLite.fromTo(threeGroup.rotation, OBJECT_TWEEN_DURATION, {
        x: 0,
        y: 0,
        z: 0
      }, {
        x: 0,
        y: threeGroupStartRotationY,
        z: 0,
        ease: Power1.easeInOut
      })
    })
  },

  beforeDestroy () {
    if (cancelAnimationFrame) {
      cancelAnimationFrame(threeAnimateFrame)
    }
    this.$refs.canvasContainer.removeEventListener('mousemove', this.onDocumentMouseMove)
    window.removeEventListener('resize', this.onWindowResize)
    window.removeEventListener('orientationchange', this.onWindowOrientationChanged)
    // removeWheelListener(this.$refs.canvasContainer, this.onMouseWheel)

    this.setCompositionScreenshotData(null)

    hammertime.destroy()

    if (touchObj.threeObjTween) {
      touchObj.threeObjTween.kill()
    }
  },

  methods: {
    ...mapMutations([
      'setCompositionScreenshotData'
    ]),

    ...mapActions([
      'setWireLength'
    ]),

    editingIdReset () {
      this.editingId = null
    },

    getCompositionDataURL () {
      let threeRendererSize = threeRenderer.getSize()
      let threeImageData = this.threeGetImageData()
      return {
        imgWidth: threeRendererSize.width,
        imgHeight: threeRendererSize.height,
        imgData: threeImageData,
        dpr: threeRenderer.getPixelRatio()
      }
    },

    initDrag () {
      hammertime = new Hammer.Manager(this.$refs.canvasContainer, { dragLockToAxis: true, preventDefault: true })

      hammertime.add(new Hammer.Pan({direction: Hammer.DIRECTION_HORIZONTAL, threshold: 9}))
      hammertime.on('panstart', this.panStart)
      hammertime.on('panmove', this.panMove)
      hammertime.on('panend', this.panEnd)
      hammertime.add(new Hammer.Tap({threshold: 9}))
      hammertime.on('tap', this.tap)

      if (this.$env.showRoomVersion !== true) {
        hammertime.add(new Hammer.Pinch())
        hammertime.on('pinch', this.pinch)
        hammertime.on('pinchstart', this.pinchStart)
        hammertime.on('pinchend', this.pinchEnd)
        hammertime.get('pinch').set({ enable: true })
      }
    },

    updateLayout () {
      wd = this.$refs.wrapper.offsetWidth // window.innerWidth
      hg = this.$refs.wrapper.offsetHeight // window.innerHeight

      threeCamera.aspect = wd / hg
      threeCamera.updateProjectionMatrix()

      threeRenderer.setPixelRatio(dpr)
      threeRenderer.setSize(wd, hg)

      touchObj.domElementRect = threeRenderer.domElement.getBoundingClientRect()
      // effectFXAA.uniforms['resolution'].value.set(1 / (wd * dpr), 1 / (hg * dpr));
      // composer.setSize(wd * dpr, hg * dpr);
    },

    orientationClass () {
      if (this.$device.isPortrait()) { // .isSm()
        if ($html) {
          $html.classList.add('portrait')
          $html.classList.remove('landscape')
        }
      } else {
        if ($html) {
          $html.classList.remove('portrait')
          $html.classList.add('landscape')
        }
      }
    },

    // 400ms and trailing true to be executed on orientation change end (ipad2 ios8 bug)
    onWindowOrientationChanged: throttle(function () {
      this.orientationClass()
      this.updateLayout()
    }, 400, { 'leading': false, 'trailing': true }),

    onWindowResize: throttle(function () {
      this.orientationClass()
      this.updateLayout()
    }, 100, { 'leading': true, 'trailing': false }),

    unZoom () {
      this.zoomActive = false
      if (touchObj.zoom > ZOOM_MIN) {
        TweenLite.to(touchObj, TWEEN_DURATION, {
          zoom: ZOOM_MIN,
          ease: Power1.easeInOut,
          onUpdate: function () {
            let fullWidth = touchObj.zoom * wd
            let fullHeight = touchObj.zoom * hg
            threeCamera.setViewOffset(fullWidth, fullHeight, (fullWidth - wd) * 0.5, (fullHeight - hg) * touchObj.offsetY / hg, wd, hg)
          }
        })
      }
    },

    setZoom (deltaZ, centerY) {
      if (!this.elements.length) {
        return
      }

      let z = touchObj.zoom + deltaZ
      touchObj.zoom = Math.max(Math.min(z, ZOOM_MAX), ZOOM_MIN)
      if (touchObj.zoom < 1.01) {
        touchObj.offsetY = centerY - touchObj.domElementRect.top
        this.zoomActive = false
      } else {
        this.zoomActive = true
      }
      let fullWidth = touchObj.zoom * wd
      let fullHeight = touchObj.zoom * hg
      threeCamera.setViewOffset(fullWidth, fullHeight, (fullWidth - wd) * 0.5, (fullHeight - hg) * touchObj.offsetY / hg, wd, hg)
      // threeCamera.zoom = Math.max(Math.min(threeCamera.zoom + ev.deltaY * 0.002, ZOOM_MAX), ZOOM_MIN)
      // threeCamera.fov = Math.max(Math.min(threeCamera.fov - ev.deltaY * 0.002, FOV_MAX), FOV_MIN)
      // threeCamera.updateProjectionMatrix()
    },

    onMouseWheel (ev) {
      this.setZoom(ev.deltaY * 0.002, ev.clientY)
    },

    pinch (ev) {
      let d = ev.scale
      let deltaZoom = d - touchObj.pinchS
      touchObj.pinchS = d
      this.setZoom(deltaZoom, ev.center.y)
    },

    pinchStart () {
      touchObj.pinchS = 1
    },

    pinchEnd () {
      touchObj.pinchS = 1
    },

    threeAnimate () {
      threeRenderer.render(threeScene, threeCamera)
      threeAnimateFrame = requestAnimationFrame(this.threeAnimate)
    },

    threeGetImageData () {
      threeRenderer.render(threeScene, threeCamera)
      return threeRenderer.domElement.toDataURL('image/png')
    },

    rotationUpdate (deltaY) {
      // threeGroup.rotation.set(0, threeGroup.rotation.y + utils.toRadians(touchObj.currMove.x - touchObj.prevMove.x), 0)
      let deltaRotationQuaternion = new THREE.Quaternion().setFromEuler(
          new THREE.Euler(0, utils.toRadians(deltaY), 0, 'XYZ')
      )
      threeGroup.quaternion.multiplyQuaternions(deltaRotationQuaternion, threeGroup.quaternion)
    },

    panStart (ev) {
      if (touchObj.threeObjTween) {
        touchObj.threeObjTween.kill()
      }
      touchObj.startTime = utils.getTime()
      this.dragging = true
      touchObj.prevMove = {
        x: ev.center.x,
        y: ev.center.y
      }
    },

    panEnd () {
      touchObj.endTime = utils.getTime()
      this.dragging = false
      let f = {
        x: 0,
        y: 0
      }
      touchObj.prevMove = {
        x: 0,
        y: 0
      }
      if (Math.abs(touchObj.deltaMove.x) > 1) {
        touchObj.threeObjTween = TweenLite.to(f, 1, {
          x: 2 * 1000 * touchObj.deltaMove.x / (touchObj.endTime - touchObj.startTime),
          ease: Power2.easeOut,
          onUpdate: function (f) {
            touchObj.currMove = {
              x: f.x,
              y: f.y
            }
            touchObj.deltaMove = {
              x: touchObj.currMove.x - touchObj.prevMove.x,
              y: touchObj.currMove.y - touchObj.prevMove.y
            }
            this.rotationUpdate(touchObj.deltaMove.x)
            touchObj.prevMove = {
              x: touchObj.currMove.x,
              y: touchObj.currMove.y
            }
          },
          onUpdateParams: [f],
          onUpdateScope: this
        })
      }
    },

    panMove (ev) {
      touchObj.swipeMovedTime = utils.getTime()
      // ev.deltaX
      touchObj.currMove = {
        x: ev.center.x,
        y: ev.center.y
      }

      touchObj.deltaMove = {
        x: touchObj.currMove.x - touchObj.prevMove.x,
        y: touchObj.currMove.y - touchObj.prevMove.y
      }

      if (this.dragging) {
        this.rotationUpdate(touchObj.deltaMove.x)
      }

      touchObj.prevMove = {
        x: touchObj.currMove.x,
        y: touchObj.currMove.y
      }
    },

    getIntersectObjects (x, y) {
      // update (window may be scrolled)
      touchObj.domElementRect = threeRenderer.domElement.getBoundingClientRect()
      mouseVector.x = ((x - touchObj.domElementRect.left) / touchObj.domElementRect.width) * 2 - 1
      mouseVector.y = -((y - touchObj.domElementRect.top) / touchObj.domElementRect.height) * 2 + 1

      // https://threejs.org/docs/#manual/introduction/Matrix-transformations
      threeGroup.updateMatrixWorld() // seems like position.set does not trigger updateMatrixWorld
      raycaster.setFromCamera(mouseVector, threeCamera)

      return raycaster.intersectObjects(threeGroup.children)
    },

    tap (ev) {
      this.editingId = null
      if (this.zoomActive) {
        return
      }
      let intersects = this.getIntersectObjects(ev.center.x, ev.center.y)
      for (let i = 0; i < intersects.length; i++) {
        if (this.$lodash.isNumber(intersects[ i ].object.userData.instanceId)) {
          this.editingId = intersects[i].object.userData.instanceId
          this.editingInstance = this.$lodash.find(this.instances, {instanceId: this.editingId})
          break
        }
      }
    },

    onDocumentMouseMove (ev) {
      ev.preventDefault()
      let intersects = this.getIntersectObjects(event.clientX, event.clientY)
      if (intersects.length > 0 && !this.zoomActive) {
        this.elementMouseOver = true
      } else {
        this.elementMouseOver = false
      }
    },

    elementSetBoxPosition (boxPosition) {
      this.meshVector = boxPosition
    }
  }
}
</script>
