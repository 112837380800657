<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
  @import "../assets/styles/global.less";

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  input[type="number"] {
      -moz-appearance: textfield;
  }

  @wd: 120px;
  @pad: 10px;
  @hg: 40px;

  .input-edit{
    position: relative;
    width: @wd;
    font-size: 14px;
    .bold();

    .input-text{
      position: relative;
      z-index: 1;
      text-align: right;
      width: @wd;
      padding: 12px 80px 8px 0;
      border: 1px solid @black;
      &:focus, &:active{
        background: @white;
        outline: none;
      }
    }
    > .unit, .edit, .confirm{
      display: block;
      position: absolute;
      cursor: pointer;
      line-height: inherit;
      z-index: 2;
    }
    > .unit{
      right: 58px;
      top: 13px;
    }
    > .edit{
      right: 16px;
      top: 13px;
    }
    .confirm{
      width: @hg;
      height: @hg;
      line-height: @hg;
      top: 1px;
      right: 0;
      background-color: @black;
      color: @white;
      text-align: center;
      display: none;
    }

    &:before{
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      right: 42px;
      top: 10px;
      height: 24px;
      background: @black;
    }

    &.focus{
      &:before, .edit{
        display: none;
      }
      .confirm{
        display: block;
      }
    }

    @media(max-width: @screen-xs-max){
      @hg: 36px;
      .input-text{
        padding: 10px 80px 6px 0;
      }
      > .unit{
        top: 10px;
      }
      .edit{
        top: 10px;
      }
      .confirm{
        height: @hg;
        line-height: @hg;
      }
    }

    &.quote-edit{
      .input-text{
        opacity: 0;
        transition: opacity .2s ease-in-out;
      }
      > .unit, &:before{
        display: none;
      }
      > .edit{
        @size: 30px;
        width: @size;
        height: @size;
        line-height: @size;
        text-align: center;
        right: 50px;
        top: 50%;
        margin-top: (-@size / 2);
        &:after{
          display: block;
          content: "";
          height: 1px;
          width: 16px;
          background-color: @black;
          position: absolute;
          bottom: 6px;
          left: 6px;
        }
      }
      .quote-value{
        position: absolute;
        top: 50%;
        left: 0;
        display: inline-block;
        background: @bg;
        padding: 0 4px;
        text-align: center;
        transform-origin: 50% 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
        left: 0;
        margin-left: 24px;
      }

      @media(max-width: @screen-xs-max){
        > .edit, .input-text{
          display: none;
        }
      }
      @media(min-width: @screen-sm-min){
        &.focus{
          .input-text{
            opacity: 1;
          }
          > .unit{
            display: block;
          }
          .quote-value{
            display: none;
          }
        }
      }
    }
  }

</style>

<template>
  <div class="input-edit" :class="{ 'focus': focus, 'quote-edit': quoteEdit }">
    <input
      class="input-text"
      type="number"
      ref="input"
      :maxlength="size" :size="size"
      :value="value"
      v-on:change="updateValue($event.target.value)"
      v-on:focus="selectAll"
      v-on:blur="formatValue"
      v-on:keyup.enter="inputBlur"
    >
    <span v-if="unit" class="unit" @click="inputFocus">{{unit}}</span>
    <span class="edit icon-edit" @click="inputFocus"></span>
    <span class="confirm icon-tick" @click="formatValue"></span>

    <span v-if="quoteEdit" class="quote-value">
      {{ value }}<span class="unit">{{ unit }}</span>
    </span>
  </div>
</template>
<script>

const numberValidator = {
  format: function (number) {
    return (Math.trunc(number * 1000000000000) / 1000000000000).toFixed(0)
  },
  parse: function (newString, oldNumber) {
    var CleanParse = function (value) {
      return { value: value }
    }
    var CurrencyWarning = function (warning, value) {
      return {
        warning: warning,
        value: value,
        attempt: newString
      }
    }
    var NotAValidDollarAmountWarning = function (value) {
      return new CurrencyWarning(newString + ' is not a valid dollar amount', value)
    }
    var AutomaticConversionWarning = function (value) {
      return new CurrencyWarning(newString + ' was automatically converted to ' + value, value)
    }

    var newNumber = Number(newString)
    var indexOfDot = newString.indexOf('.')
    var indexOfE = newString.indexOf('e')

    if (isNaN(newNumber)) {
      if (
        indexOfDot === -1 &&
        indexOfE > 0 &&
        indexOfE === newString.length - 1 &&
        Number(newString.slice(0, indexOfE)) !== 0
      ) {
        return new CleanParse(oldNumber)
      } else {
        return new NotAValidDollarAmountWarning(oldNumber)
      }
    }

    var newCurrencyString = numberValidator.format(newNumber)
    var newCurrencyNumber = Number(newCurrencyString)

    if (newCurrencyNumber === newNumber) {
      if (indexOfE !== -1 && indexOfE === newString.length - 2) {
        return new AutomaticConversionWarning(newNumber)
      } else {
        return new CleanParse(newNumber)
      }
    } else {
      return new NotAValidDollarAmountWarning(
        newNumber > newCurrencyNumber
          ? newCurrencyNumber
          : oldNumber
      )
    }
  }
}

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    quoteEdit: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: 0
    },
    size: {
      type: String,
      default: '3'
    },
    unit: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      focus: false
    }
  },

  mounted: function () {
    this.formatValue()
  },
  methods: {
    updateValue: function (value) {
      var result = numberValidator.parse(value, this.value)
      if (result.warning) {
        this.$refs.input.value = result.value
      }
      this.$emit('change', result.value)
    },
    formatValue: function () {
      this.focus = false
      this.$refs.input.value = numberValidator.format(this.value)
    },
    selectAll: function (event) {
      this.focus = true
      let isIos = this.$device.isIos()
      setTimeout(function () {
        if (isIos) {
          event.target.setSelectionRange(0, 9999)
        } else {
          event.target.select()
        }
      }, 0)
    },
    inputFocus: function () {
      this.$refs.input.focus()
    },
    inputBlur: function () {
      this.formatValue()
      this.$refs.input.blur()
    }
  }
}
</script>
