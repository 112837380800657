<style lang="less">
  @import "../assets/styles/global.less";

  .conf-modal{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    .btn-black{
      padding: 20px 0 18px;
    }

    .backdrop{
      display: block;
      position: fixed;
      z-index: 10;
      background: fade(@black, 60%);
      top: 0; left: 0; right: 0; bottom: 0;
    }
  }

  .modal-container{
    background: @white;
    width: 530px;
    height: 580px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 12;
    padding: 15px 20px 0;
    max-width: 100vw;
    max-height: 100vh;

    @media(max-width: @screen-xs-max){
      padding-top: 0;
      width: 100%;
      height: 100%;
    }
    @media(min-width: @screen-sm-min) and (max-width: @screen-sm-max){
      height: 740px;
    }
  }

  .modal-header{
    margin-bottom: 20px;
    position: relative;
    line-height: 40px;
    .main-header{
      padding: 0;
      .abs-right{
        right: 0;
      }
    }
    .counter{
      position: absolute;
      top: 0;
      left: 0;
      .light();
    }
    .title{
      padding: 0 50px;
      text-align: center;
      .bold();
      font-size: 20px;
    }
    .btn-close{
      text-align: right;
    }
    @media(max-width: @screen-xs-max){
      margin-bottom: 0;
      padding-top: 50px;
      .counter{
        top: 50px;
      }
    }
  }

  .modal-content{
    margin-bottom: 20px;
    font-size: 16px;
    @media(min-width: @screen-sm-min) and (orientation:portrait){
      font-size: 18px;
    }
    .light();
    p{
      margin-bottom: 30px;
    }
    .setting-group{
      text-align: center;
    }
  }

  .modal-footer{
    position: absolute;
    text-align: right;
    bottom: 0;
    left: 0;
    right: 0;
  }

</style>

<template>
  <transition name="fade">
    <div class="conf-modal" v-if="active">

      <div class="modal-container">
        <div class="modal-header">
          <slot name="header">
            <Header class="visible-xs">
              <button class="btn-close" @click="close"><span class="icon-close"></span></button>
            </Header>
            <h3 class="title">{{modalTitle}}</h3>
            <button class="btn-close hidden-xs" @click="close"><span class="icon-close"></span></button>
          </slot>
        </div>
        <div class="modal-content">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>

      <slot name="extra">
        <div class="backdrop" @click.prevent="close"></div>
      </slot>
    </div>
  </transition>
</template>

<script type="text/javascript">

export default {
  props: {
    modalTitle: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    close () {
      this.$emit('update:active', false)
    }
  }
}
</script>
