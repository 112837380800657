<style src="./assets/styles/app.less" lang="less"></style>

<template>
  <div id="app" class="gradient-background" :class="appClass">
    <router-view class="main-view" />
    <transition :name="panelTransitionName" mode="out-in">
      <router-view name="panel" class="panel-view" :class="panelTransitionName + '-wrapper'" :key="$route.name"></router-view>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapMutations } from 'vuex'
import throttle from 'lodash/throttle'
// import Hammer from 'hammerjs' // http://hammerjs.github.io/api/

let $html = document.querySelector('html')
// let $body = document.querySelector('body')
let showRoomWaitTimer = null

let hammertime = null

export default {

  name: 'app',

  data () {
    return {
      appClass: {'show-room-version': this.$env.showRoomVersion},
      panelTransitionName: 'panel-left'
    }
  },

  /* react to route changes */
  watch: {
    '$route' (to) {
      this.routeHandler(to)
    }
  },

  created () {
    let vm = this
    this.$http.get(this.$env.dataUrl).then(response => {
      vm.setElementsData(response.body)
      if (vm.$env.dataOvrUrl) {
        vm.$http.get(vm.$env.dataOvrUrl).then(response => {
          vm.setDataOverride({data: response.body, env: vm.$env})
        }, error => {
          console.log('Error! Could not reach the data API. ' + error)
        })
      }
    }, error => {
      console.log('Error! Could not reach the data API. ' + error)
    })

    this.routeHandler(this.$route)

    // on page reload on summary, redirect to configurator to make shure that threejs canvas is rendered, and so the canvas image
    if (this.$route.name === 'summary') {
      this.$router.replace({ name: 'configurator' })
    }
  },

  mounted () {
    $html = document.querySelector('html')
    // $body = document.querySelector('body')

    this.orientationClass()
    this.updateLayout()

    window.addEventListener('resize', this.onWindowResize, false)
    window.addEventListener('orientationchange', this.onWindowOrientationChanged, false)
    if (this.$env.showRoomVersion === true) {
      $html.addEventListener('click', this.onHtmlClick, false)
    }

    /*
    // document.body.addEventListener('touchmove', this.preventHandler, false)
    // todo: nodoubletapzoom();
    // http://hammerjs.github.io/recognizer-tap/
    // http://hammerjs.github.io/require-failure/
    let isIos = /iphone|ipad/i.test(navigator.userAgent)
    if (isIos) { // block double tap (buggy on home launch mode)
      hammertime = new Hammer.Manager(document.body, {})
      hammertime.add(new Hammer.Tap({event: 'doubletap', taps: 2}))
      hammertime.on('doubletap', this.preventHandler)
    }
    */
  },

  beforeDestroy () {
    if (hammertime) {
      hammertime.destroy()
    }

    window.removeEventListener('resize', this.onWindowResize)
    window.removeEventListener('orientationchange', this.onWindowOrientationChanged)
    if (this.$env.showRoomVersion === true) {
      $html.removeEventListener('click', this.onHtmlClick)
    }
  },

  methods: {
    ...mapActions([
      'setElementsData',
      'setDataOverride'
    ]),
    ...mapMutations([
      'deleteAllInstances'
    ]),

    onShowRoomTimerTimeout () {
      if (this.$route.name !== 'intro') {
        let _self = this
        _self.$router.replace({ name: 'intro' })
        // delay to wait the wire disappearment
        setTimeout(function goToIntro () {
          _self.deleteAllInstances()
        }, 10)
      }
    },

    onHtmlClick () {
      if (showRoomWaitTimer) {
        clearTimeout(showRoomWaitTimer)
      }
      showRoomWaitTimer = setTimeout(this.onShowRoomTimerTimeout, 30000)
    },

    routeHandler (to) {
      if (typeof (to.matched[0].components.panel) === 'object') {
        Vue.set(this.appClass, 'panel-open', true)
        $html.classList.add('panel-open')
      } else {
        Vue.set(this.appClass, 'panel-open', false)
        $html.classList.remove('panel-open')
      }

      this.panelTransitionName = (to.name === 'welcome') ? 'panel-left' : 'panel-right'
    },

    updateLayout () {
      /*
      var vpH = window.innerHeight
      document.documentElement.style.height = vpH.toString() + 'px'
      $html.style.height = vpH.toString() + 'px'
      $body.style.height = vpH.toString() + 'px'
      */
    },

    orientationClass () {
      if (this.$device.isPortrait()) { // .isSm()
        if ($html) {
          $html.classList.add('portrait')
          $html.classList.remove('landscape')
        }
      } else {
        if ($html) {
          $html.classList.remove('portrait')
          $html.classList.add('landscape')
        }
      }
    },

    // 400ms and trailing true to be executed on orientation change end (ipad2 ios8 bug)
    onWindowOrientationChanged: throttle(function () {
      this.orientationClass()
      this.updateLayout()
    }, 400, { 'leading': false, 'trailing': true }),

    onWindowResize: throttle(function () {
      this.orientationClass()
      this.updateLayout()
    }, 100, { 'leading': true, 'trailing': false }),

    preventHandler (event) {
      event.preventDefault()
    }
  }
}
</script>
