<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="../assets/styles/components/configurator.less" lang="less"></style>

<template>
  <div class="configurator-container gradient-background gradient-background--offset" :class="{'zoom-active': zoomActive, 'help-active': helpActive}">
    <Header>
      <button class="btn" @click.prevent="helpActive=true">Help</button>
    </Header>

    <div class="container-fluid container-full-height">
      <div class="row row-compostion">
        <div ref="colComposition" class="col-configurator">

          <ThreeComposition v-if="addComposition" ref="composition" @update:zoomActive="updateZoomActive"/>
          <div v-show="stateMessage" class="state-message">
            <button class="btn-close" @click="resetMessage"><span class="icon-close"></span></button>
            <span>{{ $t(stateMessage) }}</span>
          </div>

          <div class="quote quote--room-height fade-transition" :class="{'out': zoomActive}">
            <span class="start"></span>
            <span class="end"></span>

            <svg class="line" width="10" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <line stroke-dasharray="5, 5" x1="5" y1="0" x2="5" y2="100%" style="stroke-width: 1px;"></line>
            </svg>

            <InputNumber
              :quoteEdit="true"
              :unit="dimensionUnit"
              size="3"
              v-model="roomHeightValue"
              v-help="{group:'conf', index: 0}"
            />

            <div v-if="elements.length" class="quote quote--wire-length" :style="wireLengthQuoteStyle">
              <span class="start"></span>
              <span class="end"></span>

              <svg class="line" width="10" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
                 <line stroke-dasharray="5, 5" x1="5" y1="0" x2="5" y2="100%" style="stroke-width: 1px;"></line>
              </svg>

              <InputNumber
                :quoteEdit="true"
                :unit="dimensionUnit"
                size="3"
                v-model="wireLengthValue"
              />
            </div>

            <div v-if="tableHeight" class="quote quote--space-bottom" :style="tableHeightQuoteStyle">
              <span class="start"></span>
              <span class="end"></span>

              <svg class="line" width="10" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <line stroke-dasharray="5, 5" x1="5" y1="0" x2="5" y2="100%" style="stroke-width: 1px;"></line>
              </svg>

              <span class="quote-value">
                {{ tableHeightValue }}<span class="unit">{{ dimensionUnit }}</span>
              </span>
              <!--<InputNumber
                :quoteEdit="true"
                unit="cm"
                size="3"
                v-model="tableHeightValue"
              />-->
            </div>
          </div>

          <!--<svg class="composition-line top" width="100%" height="2" version="1.1" xmlns="http://www.w3.org/2000/svg">
             <line stroke-dasharray="5, 5" x1="60" y1="0" x2="100%" y2="0" style="stroke-width: 1px;"></line>
          </svg>
          <svg class="composition-line bottom" width="100%" height="2" version="1.1" xmlns="http://www.w3.org/2000/svg">
             <line stroke-dasharray="5, 5" x1="60" y1="0" x2="100%" y2="0" style="stroke-width: 1px;"></line>
          </svg>-->

          <div v-if="tableHeight" class="table-wrapper fade-transition" :class="{'out': zoomActive}" :style="tableStyle">
            <div class="table"></div>
          </div>

          <transition name="fade" mode="out-in">
            <div v-if="!elements.length" class="empty-composition-cta">
              <p v-t="'message.configuratorClickCta'"></p>
              <span class="icon icon-arrow-right-big visible-landscape"></span>
              <span class="icon icon-arrow-down visible-portrait"></span>
            </div>
          </transition>
        </div>

        <div class="col-compostion-sidebar fade-transition" :class="{'out': zoomActive}">
          <ElementSelector>
            <button slot="extra" class="btn btn-settings-open-xs" :class="{'active': settingsOpenXs}" @click="settingsOpenXs=!settingsOpenXs">
              <span class="icon icon-settings"></span>
              <span class="name" v-t="'label.settings'"></span>
            </button>
          </ElementSelector>
          <transition name="fade" mode="out-in">
            <div v-if="elements.length" key="elements-no-dimSelector-active" class="box-recap visible-landscape">
              <table class="table-full">
                <tr>
                  <td><span v-t="'label.height'"></span>:</td>
                  <td class="a-right">{{ totalHeight | convertToUnit(dimensionUnit) }} {{ dimensionUnit }}</td>
                </tr>
                <tr>
                  <td><span v-t="'label.weight'"></span>:</td>
                  <td class="a-right">{{ totalWeight | convertToUnit(weightUnit) }} {{ weightUnit }}</td>
                </tr>
                <tr>
                  <td><span v-t="'label.lumen'"></span>:</td>
                  <td class="a-right">{{ totalLumenOutput }} Lm</td>
                </tr>
                <tr>
                  <td><span v-t="'label.power'"></span>:</td>
                  <td class="a-right">{{ totalPower }} W</td>
                </tr>
              </table>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <div class="footer-compostion container-fluid">
      <div class="row-table" v-if="!zoomActive">
        <div class="col-table-content">
          <div class="box-setting" :class="{'open-xs': settingsOpenXs}">
            <!--<button class="btn-close visible-xs" @click="settingsOpenXs=false">
              <span class="icon-close"></span>
            </button>-->

            <div class="setting-group visible-xs">
              <label class="setting-label" v-t="'label.ceiling_height'"></label>
              <InputNumber
                class="pull-right"
                :unit="dimensionUnit"
                size="3"
                v-model="roomHeightValue"
              />
            </div>

            <div class="setting-group visible-xs">
              <label class="setting-label" v-t="'label.wire_length'"></label>
              <InputNumber
                class="pull-right"
                :unit="dimensionUnit"
                size="3"
                v-model="wireLengthValue"
              />
            </div>

            <div class="flex-container-landscape">
              <SettingItem v-help="{group:'conf', index: 1}" setting-label="label.positioning" setting-name="tableToggle" />

              <SettingItem v-if="powerSupplySelector" v-help="{group:'conf', index: 2}" setting-label="label.rose" setting-name="powerSupply" />

              <div class="setting-group flex-item-landscape-grow">
                <button class="btn-action" @click="deleteAllInstances" >Reset <span class="icon icon-reset"></span></button>
              </div>
            </div>

          </div>
          <transition name="fade">
            <div class="box-set-dim" v-if="dimSelectorActive">
              <SettingItem setting-label="label.dim_select" setting-name="dim" />
            </div>
          </transition>
        </div>
        <div class="col-table-sidebar">
          <div v-help="{group:'conf', index: 4}">
            <button v-if="showDimSelector && !dimSelectorActive" class="btn-black full" :class="(!elements.length || notInstallableAlone)?'disabled':''" @click="goToSummaryShowDimSelector">
              <span v-t="'label.next'"></span> <span class="icon icon-arrow-right-big"></span>
            </button>
            <button v-else class="btn-black full"
              :class="(!elements.length || notInstallableAlone)?'disabled':''" @click="goToSummary">
              <span v-t="'label.proceed'"></span> <span class="icon icon-arrow-right-big"></span>
            </button>
          </div>
        </div>
      </div>

      <a class="btn-black border full btn-black-unzoom" v-if="zoomActive" @click="unZoom" v-t="'label.zoom_out'"></a>
    </div>

    <button class="btn btn-unzoom" v-if="zoomActive" @click="unZoom">
      <span class="icon-close"></span>
    </button>

    <!--
    <ttConfiguratorModal modal-title="Dim" :active.sync="dimSelectorActive">
      <p>Testo Seleziona dim</p>
      <SettingItem setting-name="dim" />

      <button slot="footer" class="btn-black full" @click="goToSummary">Ok</button>
    </ttConfiguratorModal>
    -->

    <ConfiguratorHelp :active.sync="helpActive" />
  </div>
</template>

<script>
import InputNumber from './InputNumber.vue'
import ThreeComposition from './ThreeComposition'
import ElementSelector from './ElementSelector'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import ConfiguratorHelp from './ConfiguratorHelp'
// import ConfiguratorModal from './ConfiguratorModal'
import { utils } from '../store/mutations'

export default {
  data () {
    return {
      helpActive: false,
      dimSelectorActive: false,
      settingsOpenXs: false,
      zoomActive: false,
      dimensionUnit: this.$env.dimensionUnit,
      weightUnit: this.$env.weightUnit,
      addComposition: false,
      showRoomVersion: this.$env.showRoomVersion
    }
  },

  components: {
    InputNumber,
    ThreeComposition,
    ElementSelector,
    ConfiguratorHelp
    // ConfiguratorModal
  },

  mounted () {
    let _self = this
    setTimeout(function () {
      _self.addComposition = true
    }, 800)
  },

  computed: {
    ...mapState([
      'elements',
      'roomHeight',
      'wireLength',
      'tableHeight',
      'stateMessage',
      'showHelpSteps',
      'powerSupply',
      'powerSupplySelector',
      'dimSelector'
    ]),

    ...mapGetters([
      'totalHeight',
      'totalWeight',
      'totalPower',
      'totalLumenOutput',
      'showDimSelector',
      'notInstallableAlone'
    ]),

    wireLengthValue: {
      get () {
        return utils.convertToUnit(this.wireLength, this.dimensionUnit)
      },
      set (value) {
        this.setWireLength(utils.convertFromUnit(value, this.dimensionUnit))
      }
    },

    wireLengthQuoteStyle () {
      return {
        bottom: 'auto',
        height: (this.wireLength / this.roomHeight * 100) + '%'
      }
    },

    roomHeightValue: {
      get () {
        return utils.convertToUnit(this.roomHeight, this.dimensionUnit)
      },
      set (value) {
        this.setRoomHeight(utils.convertFromUnit(value, this.dimensionUnit))
      }
    },

    tableHeightValue: {
      get () {
        return utils.convertToUnit(this.tableHeight, this.dimensionUnit)
      },
      set (value) {
        this.setTableHeight({space: utils.convertFromUnit(value, this.dimensionUnit) / 100})
      }
    },

    tableHeightQuoteStyle () {
      return {
        top: 'auto',
        height: (this.tableHeight / this.roomHeight * 100) + '%'
      }
    },

    tableStyle () {
      let c = 3
      if (this.$refs.colComposition) {
        c = 3 * this.$refs.colComposition.offsetWidth / this.$refs.colComposition.offsetHeight
      }
      return {
        top: 'auto',
        width: (this.tableHeight / this.roomHeight * 100 * c) + '%',
        height: (this.tableHeight / this.roomHeight * 100) + '%'
      }
    }
  },

  /* react to route changes */
  watch: {
    '$route' (to) {
      if (typeof (to.matched[0]) === 'object') {
        /* open help modal */
        /*
        if (to.matched[0].name === 'configurator' && (this.showHelpSteps !== false)) {
          this.helpActive = true
          this.setShowHelpSteps(false)
        }
        */
      }
    }
  },

  methods: {
    ...mapMutations([
      'setMessage',
      'resetMessage',
      'deleteAllInstances',
      'setShowHelpSteps'
    ]),

    ...mapActions([
      'setWireLength',
      'setRoomHeight',
      'setTableHeight'
    ]),

    goToSummary () {
      if (this.elements.length) {
        if(this.notInstallableAlone) {
          this.setMessage('message.notInstallableAlone')
        } else {
          this.$router.push({name: 'summary'})
          let _self = this
          setTimeout(function () {
            _self.dimSelectorActive = false
          }, 800)
        }
      }
    },

    goToSummaryShowDimSelector () {
      if (this.elements.length && this.showDimSelector) {
        // close Xs settings box!
        this.settingsOpenXs = false
        this.dimSelectorActive = true
      }
    },

    updateZoomActive (value) {
      this.zoomActive = value
    },

    unZoom () {
      this.$refs.composition.unZoom()
    }
  }
}
</script>
