<template>
  <button @click="debounceAction('addInstance', { element: element })">
    <div class="img-wrapper">
      <img class="svg-img" :src="element.img" />
      <span class="add">+</span>
    </div>
    <p class="name">{{ element.name }}</p>
  </button>
</template>

<script>
import { mapActions } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions([ // mapped on this.$store.dispatch('addInstance', { element: element })
      'addInstance'
    ]),

    debounceAction: debounce(function (methodName, { element }) {
      let fn = this[methodName]
      if (typeof fn === 'function') {
        fn({ element })
      }
    }, 1000, { 'leading': true, 'trailing': false })
  }
}
</script>
