const messages = {
  label: {
    'start_now': 'comincia ora',
    'no_table': 'free suspension',
    'over_table': 'sopra a un tavolo',
    'surface_mounted': 'a vista',
    'recessed': 'incasso',
    'proceed': 'Finito? Procedi',
    'power': 'Potenza',
    'lumen': 'Lumen',
    'up': 'Su',
    'down': 'Giù',
    'remove': 'Rimuovi',
    'height': 'Altezza',
    'width': 'Larghezza',
    'weight': 'Peso',
    'price': 'Prezzo',
    'zoom_out': 'riduci',
    'prev': 'Indietro',
    'next': 'Avanti',
    'composition': 'composizione',
    'elements': 'elementi',
    'ceiling_height': 'Altezza del soffitto',
    'table_height': 'Altezza del tavolo',
    'wire_length': 'Lunghezza filo',
    'boxes': 'Colli',
    'request_a_quotation': 'cerca uno store',
    'find_flos_dealer': 'cerca un flos dealer',
    'download_pdf': 'download pdf',
    'add_to_basket': 'aggiungi al carrello',
    'positioning': 'Posizione',
    'region': 'Regione',
    'rose': 'Rosone',
    'dim_select': 'Scegli la regione',
    'settings': 'Preferenze'
  },
  message: {
    'welcome': 'Prima di procedere alla configurazione, dovrai solo prendere tre semplici decisioni. Ricorda che potrai cambiare idea in qualsiasi momento.',
    'welcomeRoomHeight': 'Quanto è alto il tuo soffitto?',
    'welcomeTable': 'Dove vuoi posizionare la lampada?',
    'welcomePowerSupply': 'Di che tipo di rosone hai bisogno?',
    'configuratorClickCta': 'Clicca sugli elementi della lista per creare la tua Arrangements.',
    'powerLimitExceeded': 'Non puoi aggiungere questo elemento, verrebbe superato il limite di potenza',
    'heightLimitExceeded': 'Non puoi aggiungere questo elemento, verrebbe superato il limite di altezza',
    'elementsRemoved': 'Abbiamo tolto alcuni elementi per rientrare nella nuova altezza',
    'lastElementReplaced': 'Line e Broken line sono due elementi alternativi, può essercene solo uno nella tua composizione.',
    'summaryTitle': 'La tua versione di Arrangements è pronta.',
    'summaryDescription': 'Se ti piace la composizione che hai creato, puoi scaricare questa scheda per conservarla o inviarla al nostro rivenditore ufficiale per avere un preventivo.',
    'notInstallableAlone': 'Line non può essere installato singolarmente, deve essere combinato con altri elementi'
  },
  help: {
    'title': 'Benvenuto!',
    'roomHeight': 'Modifica l\'altezza del tuo soffitto.',
    'positioning': 'Scegli se la tua Arrangements è posizionata sopra ad un tavolo.',
    'powerSupply': 'Scegli se l\'alimentazione è integrata al rosone o esterna, in caso di controsoffitto.',
    'elementSelection': 'Aggiungi gli elementi alla tua posizione. Puoi spostarli in alto e in basso o eliminarli.',
    'proceed': 'Se sei soddisfatto della tua versione di Arrangements, clicca Procedi e potrai visualizzare la tua scheda riepilogativa.'
  }
}

export default messages
