<style lang="less">
  @import "../assets/styles/global.less";

  .main-header{
    height: @mainHeaderHeight;
    display: block;
    background: @white;
    position: absolute;
    top: 0; left: 0; right: 0;
    line-height: @mainHeaderHeight;
    padding: 0 20px;
    line-height: 60px;
    font-size: 14px;
    z-index: 20;
    .abs-right{
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 10;
      line-height: 40px;
    }

    @media(max-width: @screen-xs-max){
      height: @mainHeaderHeightXs;
      line-height: @mainHeaderHeightXs;
      .abs-right{
        top: 8px;
      }
    }
  }


  .btn{
    .bold();
  }

  @media(min-width: @screen-sm-min){
    @pad: 40px;
    .main-header{
      padding: 0 @pad;
    }
    .abs-right{
      right: @pad;
    }
  }
  .flos-logo{
    font-size: 30px;
    line-height: inherit;
    float: left;
    display: block;
    span{
      line-height: inherit;
    }
    @media(max-width: @screen-xs-max){
      font-size: 22px;
    }
  }

  .header-logo{
    text-align: center;
    img{
      max-height: 40px;
      width: auto;
      margin-left: -70px;
    }
  }

  .intro-header{
    .header-logo{
      display: none;
    }
  }
</style>

<template>
  <div class="main-header">
    <a v-if="!showRoomVersion" href="https://flos.com/" class="flos-logo">
      <span class="icon-flos"></span>
    </a>
    <span v-else class="flos-logo">
      <span class="icon-flos"></span>
    </span>

    <div class="header-logo hidden-xs"><img src="../assets/images/arrangements-logo.svg" /></div>

    <div class="abs-right">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      showRoomVersion: this.$env.showRoomVersion
    }
  }
}
</script>
