<style scoped lang="less">
  @import "../assets/styles/global.less";

  .spot-light{
    @borderSize: 3000px;

    display: block;
    position: absolute;
    border-radius: 150px;
    width: 150px;
    height: 150px;
    border-radius: 50%;

    z-index: 10;
    transition: all .5s ease;
    border: @borderSize solid fade(@black, 60%);
    margin: -(@borderSize / 2);
    box-sizing: content-box;
    transform: translate3d(-(@borderSize / 2),-(@borderSize / 2),0);

    @media(max-width: @screen-xs-max){
      display: none;
    }
  }

  .animation-wrapper{
    position: relative;
    padding-bottom: 100%;
    overflow: hidden;
    margin-bottom: 10px;
    @media(min-width: @screen-md-min){
      padding-bottom: 75%;
    }
    video{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      video::-webkit-media-controls {
        display: none;
      }
    }
  }

  .btn-prev, .btn-next, .counter{
    border: none;
    background: transparent;
    padding: 0;
    font-size: 16px;
  }
  .btn-prev, .btn-next{
    padding-left: 12px;
    padding-right: 12px;
    .bold();
    &.disabled{
      .light();
    }
  }
  .btn-prev{
    padding-left: 0;
  }
  .btn-next{
    padding-right: 0;
  }
  .pages{
    padding: 0 20px 14px;
    @media(max-width: @screen-xs-max){
      padding: 0 20px 10px;
    }
  }
</style>

<template>
  <ConfiguratorModal :active.sync="active" v-if="step">
    <div slot="header">
      <Header class="visible-xs">
        <button class="btn-close" @click="close"><span class="icon-close"></span></button>
      </Header>

      <div class="counter hidden-xs">{{index + 1}}/{{helpElementsLength}}</div>
      <h3 class="title hidden-xs" v-t="step.title"></h3>

      <button class="btn-close hidden-xs" @click="close"><span class="icon-close"></span></button>
    </div>

    <div class="animation-wrapper">
      <transition name="fade">
        <video muted autoplay loop v-if="videoStart" :src="videoPath" type="video/mp4"></video>
      </transition>
    </div>

    <p v-t="step.text"></p>

    <div slot="footer" class="pages">
      <div class="counter pull-left visible-xs">{{index + 1}}/{{helpElementsLength}}</div>

      <button @click.prevent="prev" class="btn-prev" :class="prevClass">‹ <span v-t="'label.prev'"></span></button>
      <button @click.prevent="next" class="btn-next" :class="nextClass"><span v-t="'label.next'"></span> ›</button>
    </div>

    <div slot="extra" class="spot-light" :style="spotStyle" @click.prevent="close"></div>
  </ConfiguratorModal>
</template>

<script type="text/javascript">
import ConfiguratorModal from './ConfiguratorModal'
import throttle from 'lodash/throttle'
import { mapState } from 'vuex'

export default {
  components: {
    ConfiguratorModal
  },

  data () {
    return {
      index: null,
      videoPath: '',
      videoStart: false,
      windowWd: 0,
      windowHg: 0
    }
  },

  props: {
    active: {
      type: Boolean,
      default: false
    }
  },

  mounted () {
    let _self = this
    this.$nextTick(function () {
      _self.index = 0
      _self.onWindowResize()
      window.addEventListener('resize', _self.onWindowResize, false)
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onWindowResize)
  },

  computed: {
    ...mapState([
      'helpSteps'
    ]),

    step () {
      if (this.helpSteps) {
        let s = this.helpSteps[this.index]
        this.setVideoPath(s)
        return s
      } else {
        return null
      }
    },

    helpElementsLength () {
      return this.$lodash.size(this.$helpElements.conf)
    },

    prevClass () {
      return (this.index === 0) ? 'disabled' : ''
    },

    nextClass () {
      return (this.index === this.helpSteps.length - 1) ? 'disabled' : ''
    },

    spotStyle () {
      let index = this.index
      let active = this.active
      let windowWd = this.windowWd
      let windowHg = this.windowHg
      if (active && typeof (this.$helpElements.conf[index]) !== 'undefined' && windowWd && windowHg) {
        let elCurrent = this.$helpElements.conf[index]
        let elCurrentRect = elCurrent.getBoundingClientRect()
        return {
          top: (elCurrentRect.top + elCurrentRect.height / 2 - 75) + 'px',
          left: (elCurrentRect.left + elCurrentRect.width / 2 - 75) + 'px',
          width: 150 + 'px',
          height: 150 + 'px'
        }
      } else {
        return {
          width: 0 + 'px',
          heught: 0 + 'px'
        }
      }
    }
  },

  watch: {
    active: {
      immediate: true,
      handler (vNew) { //, vOld
        if (vNew) {
          this.index = 0
        }
      }
    }
  },

  methods: {
    setVideoPath (s) {
      if (!s) {
        return
      }
      this.videoStart = false
      if (this.$device.isSm()) {
        this.videoPath = s.img_smartphone
      } else {
        this.videoPath = s.img_desktop
      }

      let _self = this
      setTimeout(function () {
        _self.videoStart = true
      }, 0)
    },

    onWindowResize: throttle(function () {
      // this.setVideoPath(this.step)
      this.windowWd = window.innerWidth
      this.windowHg = window.innerHeight
    }, 300, { 'leading': true }),

    next () {
      if (this.index < this.helpSteps.length - 1) {
        this.index += 1
        if (typeof (this.$helpElements.conf[this.index]) === 'undefined') {
          this.next()
        }
      } else {
        // this.index = 0
        this.close()
      }
    },

    prev () {
      if (this.index > 0) {
        this.index -= 1
        if (typeof (this.$helpElements.conf[this.index]) === 'undefined') {
          this.prev()
        }
      } else {
        // this.index = this.helpSteps.length - 1
        this.close()
      }
    },

    close () {
      this.$emit('update:active', false)
      this.index = null
    }
  }
}
</script>
