import find from 'lodash/find'
import Lodash from 'lodash'
import { SPACE_TOP_MIN, SPACE_BOTTOM_MIN, TABLE_DISTANCE, getElementHeight } from './mutations'

// these elements_ids can't be installed alone
const NOT_ALONE_ELEMENTS = ['a']

export const getters = {
  instances: (state) => {
    let instancesArr = []
    state.elements.forEach((item) => {
      let element = find(state.elementsData, {id: item.elementId})
      if (element) {
        // if you need to alter element, clone it before!
        // element = this.$lodash.clone(element)
        instancesArr.push({instanceId: item.instanceId, element: element})
      }
    })
    return instancesArr
  },

  compostionString: (state, getters) => {
    let arr = []
    // arr.push(state.dim)
    // arr.push(state.powerSupply)
    let r = (getters.rosone) ? getters.rosone : null
    if (r) {
      arr.push(r.id)
    }

    getters.instances.forEach((instance) => {
      arr.push(instance.element.id)
    })

    return arr.join('-')
  },

  showDimSelector: (state, getters) => {
    return (state.dimSelector === true) && (state.powerSupply === 'embedded') && (getters.totalPower > 70)
  },

  rosone: (state, getters) => {
    let r

    if (state.powerSupply === 'remote' && Lodash.isObject(state.rosoniData.rC3Surface)) {
      r = state.rosoniData.rC3Surface
    } else {
      if (getters.totalPower > 70) {
        if (state.dim === 'eur' && Lodash.isObject(state.rosoniData.rBigEur)) {
          r = state.rosoniData.rBigEur
        } else {
          r = state.rosoniData.rBigUl
        }
      } else {
        r = state.rosoniData.rSmall
      }
    }

    return r
  },

  bottomSpace: (state) => {
    return (state.tableHeight) ? (state.tableHeight + TABLE_DISTANCE) : SPACE_BOTTOM_MIN
  },

  totalHeightMax: (state, getters) => {
    return state.roomHeight - SPACE_TOP_MIN - getters.bottomSpace
  },

  notInstallableAlone: (state, getters) => {
    if (getters.instances.length === 1) {
      const instance = getters.instances[0]
      if (instance.element && Lodash.indexOf(NOT_ALONE_ELEMENTS, instance.element.id) !== -1) {
        return true
      }
    }
    return false
  },

  // https://developer.mozilla.org/it/docs/Web/JavaScript/Reference/Global_Objects/Array/Reduce
  totalHeight: (state, getters) => {
    return getters.instances.reduce((prev, i, index) => {
      return prev + getElementHeight(i.element, index, true)
    }, 0)
  },

  totalPrice: (state, getters) => {
    let base = 0
    if (getters.rosone) {
      base = (getters.rosone.price) ? getters.rosone.price : 0
    }
    return getters.instances.reduce((prev, i) => {
      return prev + i.element.price
    }, base)
  },

  totalWeight: (state, getters) => {
    let base = (getters.rosone) ? getters.rosone.weight : 0

    return getters.instances.reduce((prev, i) => {
      return prev + i.element.weight
    }, base)
  },

  totalPower: (state, getters) => {
    return getters.instances.reduce((prev, i) => {
      return prev + i.element.power
    }, 0)
  },

  totalLumenSource: (state, getters) => {
    return getters.instances.reduce((prev, i) => {
      return prev + i.element.lm_s
    }, 0)
  },

  totalLumenOutput: (state, getters) => {
    return getters.instances.reduce((prev, i) => {
      return prev + i.element.lm_o
    }, 0)
  }
}
