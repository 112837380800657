<style scoped lang="less">
  @import "../assets/styles/global.less";

  .container-intro{
    padding-left: 0;
    padding-right: 0;
  }

  .transition-opacity{
    transition: opacity .5s ease-in-out;
    opacity: 0;
    &.in{
      opacity: 1;
    }
  }
  .btn-black{
    padding: 14px 60px 12px;
  }
  .author{
    position: absolute;
    font-weight: 700;
    font-size: 18px;
    /*
    @media(min-width: @screen-sm-min){
      font-size: 24px;
    }
    .role{
      font-size: 16px;
    }
    */
    width: 400px;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
  }
  .shapes{
    @d: 0.3s;
    position: relative;
    @size: 240px;
    width: @size;
    height: @size;
    margin: 0 auto 40px;
    .anim{
      position: absolute;
      width: @size;
      height: @size;
      display: block;
      left: 0;
      top: 0;
      transition: all .3s ease-in-out;
      transform: scale(0.2);
      opacity: 0;
      &.in{
        animation: bounce-in .8s;
      }

      .inAnimation(){
        30% {
          transform: scale(1);
          opacity: 1;
        }
        50% {
          transform: scale(1.8);
          opacity: 1;
        }
        100% {
          transform: scale(2.2);
          opacity: 0;
        }
      }
      @-webkit-keyframes bounce-in {
        .inAnimation();
      }
      @-moz-keyframes bounce-in {
        .inAnimation();
      }
      @-o-keyframes bounce-in {
        .inAnimation();
      }
      @keyframes bounce-in {
        .inAnimation();
      }
    }
    .title{
      position: absolute;
      display: block;
      transition: all .5s ease-in-out;
      transform: scale(0.5);
      opacity: 0;
      @wd: 300px;
      &.in{
        transform: scale(1);
        opacity: 1;
      }
      img{
        width: @wd;
        height: auto;
      }
      height: 70px;
      left: ((@size - @wd) / 2);
      top: 80px;
      text-shadow: center;
      @media(min-width: @screen-sm-min){
        @wd: 380px;
        img{
          width: @wd;
        }
        left: ((@size - @wd) / 2);
      }
    }
  }
  .abs{
    display: block;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    z-index: 1;
  }
  .vert-center{
    position: relative;
    z-index: 20;
  }
</style>

<template>
  <div class="container-intro container-fluid">
    <Header class="intro-header"></Header>
    <div class="container-full-height">
      <router-link class="abs" :to="{name: 'welcome'}"></router-link>
      <div class="vert-center">
        <div class="shapes">
          <img ref="item-0" class="anim" src="../assets/images/intro/line.svg" />
          <img ref="item-1" class="anim" src="../assets/images/intro/broken_line.svg" />
          <img ref="item-2" class="anim" src="../assets/images/intro/square_l.svg" />
          <img ref="item-3" class="anim" src="../assets/images/intro/drop_up.svg" />
          <img ref="item-4" class="anim" src="../assets/images/intro/drop_down.svg" />
          <img ref="item-5" class="anim" src="../assets/images/intro/round_m.svg" />
          <div ref="item-6" class="title">
            <img src="../assets/images/arrangements-logo.svg" />
          </div>
          <span ref="item-7" class="author transition-opacity"><span class="role">Design</span> Michael Anastassiades</span>
        </div>

        <span ref="item-8" class="transition-opacity">
          <router-link class="btn-black border " :to="{name: 'welcome'}" v-t="'label.start_now'"></router-link>
        </span>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">

export default {
  mounted () {
    let itemIndex = 0
    let _self = this
    let itemInterval = setInterval(function itemAnimate () {
      if (typeof (_self.$refs['item-' + itemIndex]) !== 'undefined') {
        _self.$refs['item-' + itemIndex].classList.add('in')
      } else {
        clearInterval(itemInterval)
      }
      itemIndex++
    }, 300)
  }
}
</script>
