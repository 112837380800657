let help = {}

let helpElements = {}

help.install = function (Vue) {
  /*
  Vue.helpElements = function () {
    return helpElements
  }
  */
  Object.defineProperty(Vue.prototype, '$helpElements', { value: helpElements })
  /*
  Vue.prototype.$myMethod = function (methodOptions) {
    // something logic ...
  }
  */

  Vue.directive('help', {
    bind: function (el, binding) { //, vnode, oldVnode
      if (binding.value) {
        if (typeof (binding.value) === 'object') {
          if (!helpElements[binding.value.group]) {
            helpElements[binding.value.group] = {}
          }
          helpElements[binding.value.group][binding.value.index] = el
        }
      }
    },
    unbind: function (el, binding) { //, vnode, oldVnode
      if (binding.value) {
        if (typeof (binding.value) === 'object') {
          delete helpElements[binding.value.group][binding.value.index]
        }
      }
    }
  })
}

export default help
