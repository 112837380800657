
import BrokenLine from '../assets/models/broken-line.json'
import DropUp from '../assets/models/drop-up.json'
import Line from '../assets/models/line.json'
import RoundL from '../assets/models/round-l.json'
import RoundM from '../assets/models/round-m.json'
import RoundS from '../assets/models/round-s.json'
import SquareL from '../assets/models/square-l.json'
import SquareS from '../assets/models/square-s.json'
import Wire from '../assets/models/wire.json'
let models = {BrokenLine, DropUp, Line, RoundL, RoundM, RoundS, SquareL, SquareS, Wire}

/*
import m from '../assets/models/round-s.json'
models = {BrokenLine: m, DropDown: m, Line: m, RoundL: m, RoundM: m, RoundS: m, SquareL: m, SquareS: m, Wire: m}
*/
/*
let models = {
  BrokenLine: window.BrokenLine,
  DropUp: window.DropUp,
  Line: window.Line,
  RoundL: window.RoundL,
  RoundM: window.RoundM,
  RoundS: window.RoundS,
  SquareL: window.SquareL,
  SquareS: window.SquareS,
  Wire: window.Wire
}
*/
// https://stackoverflow.com/questions/28572380/conditional-build-based-on-environment-using-webpack

const getModel = (key) => {
  return models[key]
}
const resetModels = () => {
  models = null
}

export default {getModel, resetModels}
