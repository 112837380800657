// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'promise-polyfill/src/polyfill'
import Vue from 'vue'
import VueResource from 'vue-resource'
import App from './App'
import store from './store'
import router from './router'
import Lodash from 'lodash'
import Vue2Filters from 'vue2-filters' // user for currency
import VueI18n from 'vue-i18n'
import Help from './plugins/help'
import { utils } from './store/mutations'
import messagesIt from './i18n/it'
import messagesEn from './i18n/en'
import Header from './components/Header'
import SettingItem from './components/SettingItem'
import { detector } from './detector'

Vue.config.productionTip = false

Vue.use(VueResource)

// console.log(process.env)

Vue.use(Vue2Filters) // user for currency

/* https://lodash.com/docs/4.17.5 */
Object.defineProperty(Vue.prototype, '$lodash', { value: Lodash })

/* Breakpoint related functions */
Object.defineProperty(Vue.prototype, '$device', { value: {
  isTouch: function () {
    // return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0))
    return (
      !!(typeof window !== 'undefined' &&
        ('ontouchstart' in window ||
          (window.DocumentTouch &&
            typeof document !== 'undefined' &&
            document instanceof window.DocumentTouch))) ||
      !!(typeof navigator !== 'undefined' &&
        (navigator.maxTouchPoints || navigator.msMaxTouchPoints))
    )
  },
  isIos: function () {
    // return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  },
  isPortrait: function () {
    // when keaborad is open, poprtrait smartphone became landscape! prevent this!
    return (window.innerWidth < window.innerHeight) || (window.innerWidth < 992)
  },
  isXs: function () {
    return window.innerWidth < 768
  },
  isSm: function () {
    return window.innerWidth < 992
  },
  isMd: function () {
    return window.innerWidth < 1200
  }
}})

let env = {
  defaultFormMethod: 'post',
  showRoomVersion: false,
  locale: 'en',
  dataUrl: process.env.DATA_URL,
  dimensionUnit: 'cm',
  weightUnit: 'kg',
  priceEnable: false,
  priceCurrency: '€',
  priceNote: '',
  downloadUrl: '',
  addToBasketUrl: '',
  storefinderUrl: '',
  quotationRequestUrl: ''
}

if (Lodash.isObject(window.app_env)) {
  if (Lodash.isString(window.app_env.defaultFormMethod)) {
    if (window.app_env.defaultFormMethod.toLowerCase() === 'get') {
      env.defaultFormMethod = window.app_env.defaultFormMethod.toLowerCase()
    }
  }

  if (Lodash.isString(window.app_env.locale)) {
    env.locale = window.app_env.locale
  }

  if (Lodash.isString(window.app_env.DATA_URL)) {
    env.dataUrl = window.app_env.DATA_URL
  }

  if (Lodash.isString(window.app_env.DATA_OVR_URL)) {
    env.dataOvrUrl = window.app_env.DATA_OVR_URL
  }

  if (Lodash.isString(window.app_env.DOWNLOAD_URL)) {
    env.downloadUrl = window.app_env.DOWNLOAD_URL
  }

  if (Lodash.isString(window.app_env.ADDTOBASKET_URL)) {
    env.addToBasketUrl = window.app_env.ADDTOBASKET_URL
  }

  if (Lodash.isString(window.app_env.STOREFINDER_URL)) {
    env.storefinderUrl = window.app_env.STOREFINDER_URL
  }

  if (Lodash.isString(window.app_env.QUOTATIONREQUEST_URL)) {
    env.quotationRequestUrl = window.app_env.QUOTATIONREQUEST_URL
  }

  if (Lodash.isBoolean(window.app_env.priceEnable)) {
    env.priceEnable = window.app_env.priceEnable
  }

  if (Lodash.isString(window.app_env.priceCurrency)) {
    env.priceCurrency = window.app_env.priceCurrency
  }

  if (Lodash.isString(window.app_env.priceNote)) {
    env.priceNote = window.app_env.priceNote
  }

  if (Lodash.isString(window.app_env.dimensionUnit)) {
    if (window.app_env.dimensionUnit === 'cm' || window.app_env.dimensionUnit === 'in') {
      env.dimensionUnit = window.app_env.dimensionUnit
    }
  }

  if (Lodash.isString(window.app_env.weightUnit)) {
    if (window.app_env.weightUnit === 'kg' || window.app_env.weightUnit === 'lbs') {
      env.weightUnit = window.app_env.weightUnit
    }
  }

  if (Lodash.isBoolean(window.app_env.showRoomVersion)) {
    env.showRoomVersion = window.app_env.showRoomVersion
  }
}
Object.defineProperty(Vue.prototype, '$env', {value: env})

Vue.use(Help)

Vue.use(VueI18n)
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: env.locale, // set locale
  fallbackLocale: 'en',
  silentTranslationWarn: true, // disable translation warnings
  messages: {
    en: messagesEn,
    it: messagesIt
  } // set locale messages
})

Vue.filter('convertToUnit', function (value, unit) {
  if (!value) return ''
  return utils.convertToUnit(value, unit)
})
Vue.filter('decimal', function (value) {
  if (!value) return ''
  let pow = Math.pow(10, 2)
  return parseInt(value * pow) / pow
})

// Global components
Vue.component('Header', Header)
Vue.component('SettingItem', SettingItem)

/*
new Vue({
  render: h => h(App),
}).$mount('#app')
*/
if (detector.webgl) {
  /* eslint-disable no-new */
  new Vue({
    i18n,
    router, // inject store to all children
    store, // inject store to all children
    el: '#app',
    render: h => h(App)
  })
} else {
  var warning = detector.getWebGLErrorMessage()
  document.getElementById('app').appendChild(warning)
}
