<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
  @import "../assets/styles/global.less";

  .element-selector-row{
    text-align: center;
    margin-left: 0;
    margin-right: 0;
    .item{
      background: none;
      border: none;
      position: relative;
      display: inline-block;
      cursor: pointer;
      transition: opacity .3s ease-in-out;
      padding-left: 0;
      padding-right: 0;
      .img-wrapper{
        overflow: hidden;
        position: relative;
        height: @elementSelectorHeightXs;
        .add{
          @size: 24px;
          display: block;
          position: absolute;
          bottom: 0;
          right: 0;
          width: @size;
          height: @size;
          line-height: @size;
          text-align: center;
          background: @black;
          color: @white;
          .bold();
          opacity: 0;
          transition: opacity .3s ease-in-out;
        }
      }
      .svg-img{
        position: absolute;
        left: 50%;
        transform-origin: 0 0 0;
        transform: scale(0.25) translateX(-50%);
        margin-top: -10px;
      }
      .name{
        text-transform: uppercase;
        font-size: 10px;
      }
    }

    //@media(orientation:portrait){
    .portrait & {
      font-size: 0;
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
      width: 100%;
      white-space: nowrap;
      /*
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      */

      .item{
        display: inline-block;
        /*
        flex: 0 0 auto;
        */

        font-size: 12px;
        position: relative;
        height: @elementSelectorHeightXs;
        width: 76px;
        .img-wrapper{
          .add{
            @size: 18px;
            width: @size;
            height: @size;
            line-height: @size;
            opacity: 1;
            top: 0;
            bottom: auto;
            background: transparent;
            color: @grayMed;
          }
        }
        .name{
          position: absolute;
          bottom: 2px;
          left: 0;
          right: 0;
          .bold();
        }
      }
      @media(min-width: @screen-sm-min){
        display: table;
        width: 100%;
        table-layout: fixed;
        .item{
          display: table-cell;
          width: 11.11%;
          float: none;
        }
      }
    }

    //@media(orientation:landscape){
    .landscape &{
      @pad: 5px;
      margin-left: (-@pad);
      margin-right: (-@pad);
      .item{
        padding-left: @pad;
        padding-right: @pad;
        display: block;
        .img-wrapper{
          border-bottom: 1px solid fade(@gray, 20%);
          height: 82px;
          margin-bottom: 10px;
        }
        .svg-img{
          transform-origin: 0 0 0;
          transform: scale(0.38) translateX(-50%);
          margin-top: 0;
        }
        .name{
          font-size: 12px;
          .bold();
        }
      }

      /* todo: modernizr .touch */
      &:hover{
        .item{
          opacity: 0.5;
          &:hover{
            opacity: 1;
            .img-wrapper{
              .add{
                opacity: 1;
              }
            }
          }
        }
      }
      @media(min-width: @screen-lg-min) and (min-height: 800px){
        .item{
          .img-wrapper{
            height: 112px;
          }
          .svg-img{
            transform: scale(0.48) translateX(-50%);
            margin-top: 8px;
          }
        }
      }
    }

  }
  .box-title{
    font-size: 24px;
  }

  .element-selector-wrapper{
    .loading{
      display: block;
      padding: 20px 0;
    }
  }
</style>

<template>
  <div class="element-selector-wrapper">
    <slot name="extra"></slot>
    <div v-if="elementsData.length" class="row element-selector-row">
      <ElementSelectorItem
        class="item col-sm-4"
        v-for="(element, index) in elementsData"
        :key="element.id"
        :element="element"
        v-help="(index===5)?{group:'conf', index: 3}:null"
      />
    </div>
    <p v-else>
      <span class="loading">Loading...</span>
    </p>
  </div>
</template>

<script>
import ElementSelectorItem from './ElementSelectorItem.vue'
import { mapState } from 'vuex'

export default {
  components: {
    ElementSelectorItem
  },

  computed: {
    /* elementsData () { return this.$store.state.elementsData }, */
    ...mapState([
      'elementsData'
    ])
  }
}
</script>
