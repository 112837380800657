<style lang="less">
  @import "../assets/styles/global.less";

  .setting-group{
    white-space: nowrap;
    font-size: 0;
    @media(min-width: @screen-md-min){
      line-height: 20px; // on md/lg settings om the same line of proceed button
    }
    .setting-label{
      font-size: 12px;
      .bold();
      margin-right: 8px;
      padding: 12px 0 8px;
      @media(max-width: @screen-sm-max){
        margin-right: 10px;
      }
    }
    .setting-item, .btn-action{
      border: 0 none;
      background: transparent;
      text-transform: uppercase;
      padding: 4px 14px 0; //12px 14px 8px;
      line-height: 36px;
      border: 1px solid @black;
      .bold();
      color: @black;
      font-size: 12px;
      .icon{
        font-size: 16px;
        position: relative;
        top: 2px;
      }
      &.active{
        color: @white;
        background-color: @black;
      }
      @media(max-width: @screen-md-max){
        font-size: 11px;
        padding-left: 8px;
        padding-right: 8px;
        //min-width: 100px;
      }
    }
  }
</style>

<template>
  <div class="setting-group">
    <label v-if="settingLabel" class="setting-label" v-t="settingLabel"></label>

    <template v-if="settingName === 'tableToggle'">
      <button :class="(!tableHeight) ? 'active' : ''" class="setting-item" @click="unpreserveBottomSpace" v-t="'label.no_table'"></button>
      <button :class="(tableHeight) ? 'active' : ''" class="setting-item" @click="preserveBottomSpace" v-t="'label.over_table'"></button>
    </template>
    <template v-else-if="settingName === 'powerSupply'">
      <button
        :class="(powerSupply === option.value) ? 'active' : ''" class="setting-item"
        v-for="(option, index) in POWERSUPPLY_OPTIONS"
        :key="index + '-' + option.text"
        @click="setPowerSupply(option.value)"
        v-t="'label.' + option.text"
      ></button>
    </template>
    <template v-else-if="settingName === 'dim'">
      <button
        :class="(dim === option.value) ? 'active' : ''" class="setting-item"
        v-for="(option, index) in DIM_OPTIONS"
        :key="index + '-' + option.text"
        @click="setDim(option.value)"
      >{{option.text}}</button>
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { DIM_OPTIONS, POWERSUPPLY_OPTIONS } from '../store/mutations'

export default {
  props: {
    settingLabel: {
      type: String
    },
    settingName: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      DIM_OPTIONS,
      POWERSUPPLY_OPTIONS
    }
  },

  computed: {
    ...mapState([
      'tableHeight',
      'powerSupply',
      'dim'
    ])
  },

  methods: {
    ...mapMutations([
      'setDim',
      'setPowerSupply'
    ]),

    ...mapActions([
      'preserveBottomSpace',
      'unpreserveBottomSpace'
    ])
  }
}
</script>
